import React from 'react';
import Device from "./Device";

let newliner = str => str.split(/\n/).map((s, i) => <div key={i}>{s}</div>)

export default ({children, small, mobileHeaders, headers, headersTr, cs, objs, data, td, tds, tr, row, render, style={}, ...props}) => {
  style.width = style.width || "100%";
  if (small)
    cs = "ant-table-small";

  if (mobileHeaders && Device.xs)
    headers = mobileHeaders;

  return <table className={"ant-table round-table " + cs} style={style} {...props}>
    <tbody className="ant-table-tbody">
    {headers && <tr style={{fontWeight: 'bold'}}>{headers.map((h, i) => <td key={i}>{newliner(h)}</td>)}</tr>}
    {headersTr}
    {render && objs.map((o, i) => render(o, i))}
    {tr && objs.map((o, i) => <tr key={o.id || i}>{tr(o, i)}</tr>)}
    {row && objs.map((o, i) => row(o, i))}
    {td && objs.map((o, i) => <tr key={o.id || i}><td>{td(o, i)}</td></tr>)}
    {tds && objs.map((o, i) => <tr key={o.id || i}>{tds(o, i).map((v, j) => <td key={j}>{v}</td>)}</tr>)}
    {data && data.map((o, i) => <tr key={i}>{o.map((v, j) => <td key={j}>{v}</td>)}</tr>)}
    {children}
    </tbody>
  </table>
}

