import {observer} from "mobx-react";
import {
  Form,
  Col,
  Row,
  Drawer,
  Button,
  Space,
  Skeleton,
  Spin,
  Menu,
  Select,
  Popover, Card
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import LineSection from "./LineSection";

import ArticleStore from "./ArticleStore";
import MarkedLine from "./MarkedLine";
import MyDrawer from "./MyDrawer";
import { Alert, Radio } from 'antd';
import Input from "antd/es/input";
// import { Bot } from 'lucide-react';

import {
  AuditOutlined, BookOutlined, ContainerOutlined, FileDoneOutlined, FileSearchOutlined,
  FileWordTwoTone, BulbOutlined,
} from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import Searcher from "./Searcher";
import {observable} from "mobx";
import TextSelectionActions from "./TextSelectionActions";
import TextSelection from "./TextSelection";
import MMPop from "./MMPop";
import {ColorArea} from "./ColorArea";
import HtmlDiv from "./HtmlDiv";
import User from "./User";
import utils, {callWithCatch, pp} from "./utils";
import Loader from "./Loader";
import Switcher from "./Switcher";
import {useForm} from "antd/es/form/Form";
import MyWS from "./MyWS";
import {Bot} from "lucide-react";
import Device from "./Device";

import _ from 'lodash';


let mobile = window.innerWidth < 700;

let admin = gon && gon.user && gon.user.plan === 'admin'

let toWords = (s) => _.filter(s.split(/\s+/g), s => s.length > 3)

export default observer(({term, dev, articles}) => {
  let article = ArticleStore.article;

  let [mode, setMode] = useState(ArticleStore.openSummary ? 'summary' : 'article');

  useEffect(() => {
    focusStore.setTerm(term);
  }, []);

  useEffect(() => {
    let {locate} = ArticleStore;

    setTimeout(() => {
      if (mode === "summary") {
        let drawer = document.querySelector(".ant-drawer-body");

        if (drawer)
          drawer.scrollTop = 0;
      }

      if (!locate || !locate.length || mode !== "article")
        return;

      let lw = toWords(locate);

      let els = _.map(document.querySelectorAll('.article-body span, .article-body .line-section'), el => [el, toWords(el.innerText)]);

      let result = _.maxBy(els, el => _.intersection(lw, el[1]).length)

      if (result) {
        result[0].classList.add("line-selected");
        result[0].scrollIntoView({ behavior: 'instant', block: 'center' });
      }
    }, 32)
  }, [article, mode]);

  if (!ArticleStore.loading)
    return;

  let summary = article && article.summary;

  let close = () => {
    ArticleStore.loading=false;
    ArticleStore.article=null;
    ArticleStore.openSummary=false;
    briefStore.show = false;
    history.pushState({}, null, "/");
  };

  let skira = article && article.kind === 2;

  let menu = [];

  if (article) {
    if (!skira) {
      menu.push(["article", "מסמך", <ContainerOutlined />]);
      menu.push(["summary", "תקציר", <FileDoneOutlined />]);
    }
    else
      menu.push(["article", "אוזכר ב", <AuditOutlined />]);

    menu.push(["focus", "חיפוש פנימי", <FileSearchOutlined />]);

    if (!skira) {
      menu.push(["skira", "אוזכר ב", <AuditOutlined />, !article.show_skira]);
      menu.push(["refs", "הליכים שאוזכרו", <BookOutlined />, !ArticleStore.ref_case_nums || !ArticleStore.ref_case_nums.length]);
      // menu.push(["skira", "אוזכר ב", <MenuUnfoldOutlined rotate={180}/>]);
      // menu.push(["refs", "איזכר את", <MenuFoldOutlined />,]);
    }

    // if (User.isAdmin())
      menu.push(["chat", "ניתוחי AI", Device.xs ? <Bot /> : <BulbOutlined />]);

    // if (User.isAdmin() && article.id === 47248)
    //   menu.push(["emb", "בדיקה"]);
  }

  let handleModeChange = e => {
    let k = (e.target && e.target.value) || e.key || e;

    if (k === "brief") {
      if (!briefStore.show) {
        // focusStore.term = "טענה";
        setMode("focus");
        briefStore.edit = "raw";
        briefStore.loading = true;
      }
      briefStore.show = !briefStore.show
      return;
    }

    if (k !== "edit" && _.find(menu, m => m[0] === k)[3])
      return;

    setMode(k);
  };

  let header = <div>
    <Row justify="end">
      <Col md={18} xs={0}>
        {article && <div>
          <div style={{fontSize: "12px", color: "#9CA3AF", direction: "rtl"}}>
            <MMPop doc={ArticleStore.asDoc()} placement="right" asClick>
              <span>{article.short_case_type} {article.case_num}</span> &#8226; {article.court_name}
            </MMPop>
          </div>
          {article.case_title}
        </div>}
        <div>{ summary && <div style={{fontSize: "16px", color: "#9CA3AF", borderRight: "5px solid #E5E7EB", paddingRight: "4px"}}>{summary}</div> }</div>
      </Col>
      <Col md={6}>
        <Space>
          <Radio.Group onChange={handleModeChange} value={mode} buttonStyle="solid">
            {admin && !skira && <Radio.Button value="edit">{"ע"}</Radio.Button>}
            {admin && !skira && !mobile && <Radio.Button value="brief">טענות</Radio.Button>}
          </Radio.Group>
          {!skira && article && <Button icon={<FileWordTwoTone />} href={ArticleStore.dl_url}/>}
        </Space>
      </Col>
    </Row>
  </div>;

  let inner = <>
    {mode === "refs" && <Refs id={ArticleStore.id} setMode={setMode} /> }
    {mode === "skira" && <Skira id={ArticleStore.id}/> }
    {mode === "edit" && <Edit id={ArticleStore.id}/> }
    {mode === "article" && <Article dev={dev} /> }
    {mode === "summary" && <SuperSummary setMode={setMode} /> }
    {mode === "focus" && <Focus setMode={setMode} dev={dev}/> }
    {mode === "chat" && <Chat setMode={setMode} dev={dev}/> }
    {mode === "emb" && <EmbTest setMode={setMode} dev={dev}/> }
  </>;

  if (!article)
    inner = <Skeleton active />;

  if (mobile)
    header = <>
      <div>
      { article && <MMPop doc={ArticleStore.asDoc()} placement="right" forceLink={true}>
        <b>{article.short_case_type} {article.case_num}</b>
      </MMPop> }
    </div>
    <div>{!skira && mode === "article" && <Button icon={<FileWordTwoTone />} href={ArticleStore.dl_url}/>}</div>
    </>

  return <div>
    <MyDrawer tabs={menu} inner={inner} rightDrawer={briefStore.show} close={close} mode={mode} handleModeChange={handleModeChange} header={header} />
    { briefStore.show && <BriefEditor close={close} articles={articles}/> }
  </div>
});

export const briefStore = observable({
  brief: null,
  id: null,
  edit: "raw",
  show: false,
  dirty: false,
  loading: false,
  btnLoading: false,

  get text() {
    return briefStore.brief[briefStore.edit];
  },

  saveNow: async () => {
    let url = "/articles/" + ArticleStore.id + "/brief";
    let setLoading = l => briefStore.btnLoading = l;

    callWithCatch({
      url,
      method: 'post',
      setLoading,
      params: briefStore.brief,
      onSuccess: () => briefStore.dirty = false
    })
  },
  save: _.debounce(async () => {
    await briefStore.saveNow();
    briefStore.dirty = false;
  }, 3000),

  set: t => {
    briefStore.brief[briefStore.edit] = t;
    briefStore.dirty = true;
  }
});

let BriefEditor = observer(({close, articles}) => {
  let briefUrl = "/articles/" + ArticleStore.id + "/brief";

  let setStatus = e => {
    let s = e.target.value;
    briefStore.brief.status = s;

    briefStore.saveNow();

    if (articles[ArticleStore.id])
      articles[ArticleStore.id].brief_status = s;

    if (s === "bad" || s === "done" || s === "ai_done")
      close();
  }

  let setSubject = e => {
    briefStore.brief.subject = e.target.value;
    briefStore.dirty = true;
  }

  let setEdit = e => {
    briefStore.edit = e.key;
  }

  let setText = e => {
    briefStore.set(e.target.value);
  }

  useEffect(() => {
    let load = async () => {
      let b = (await axios.get(briefUrl)).data.brief;
      briefStore.brief = b;
      briefStore.dirty = false;
      briefStore.loading = false;
    }
    load();
  }, []);

  let brief = briefStore.brief;

  if (!brief)
    return <Drawer
      // title={}
      width={"40%"}
      placement="right"
      closable={false}
      visible={true}
      onClose={close}
      style={{zIndex: 100}}
    />

  let selectors;
  if (brief.raw?.length && briefStore.edit !== "raw") {
    let briefByTypes = {
      "כתב תביעה": ["התובע", "התובעת", "התובעות", "התובעים", ],
      "כתב הגנה": ["הנתבע", "הנתבעת", "הנתבעות", "הנתבעים", ],
      "בקשה": ["המבקש", "המבקשת", "המבקשות", "המבקשים", ],
      "כתב תשובה": ["המשיב", "המשיבה", "המשיבות", "המשיבים", ],
      "כתב עתירה": ["העותר", "העותרת", "העותרות", "העותרים", ],
      "כתב ערעור": ["המערער", "המערערת", "המערערות", "המערערים", ],
      "כתב ערער": ["העורר", "העוררת", "העוררות", "העוררים", ],
    }

    let getBriefByTypes = (type) => {
      return briefByTypes[type] || ["המשיב", "המשיבה", "המשיבות", "המשיבים", ]
    }

    let type = brief[briefStore.edit + "_type"]
    let by = brief[briefStore.edit + "_by"]

    let types = briefStore.edit === "claims" ? ["כתב תביעה", "בקשה", "כתב עתירה", "כתב ערעור", "כתב ערער"] : ["כתב הגנה", "כתב תשובה", "ללא"];

    let setType = v => {
      brief[briefStore.edit + "_type"] = v;
      brief[briefStore.edit + "_by"] = null;
      briefStore.dirty = true;
    }

    let setBy = v => {
      brief[briefStore.edit + "_by"] = v;
      briefStore.dirty = true;
    }

    selectors = <div style={{marginBottom: "1rem"}}>
      <Space>
        <Select options={types.map(t => ({label: t, value: t}))}
                value={type}
                onChange={setType}
                style={{width: "175px"}}
        />
        { type && <Select options={getBriefByTypes(type).map(t => ({label: t, value: t}))}
                          value={by}
                          onChange={setBy}
                          style={{width: "175px"}}
        /> }
      </Space>
    </div>
  }

  return <Drawer
    // title={}
    width={"40%"}
    placement="right"
    closable={false}
    visible={true}
    onClose={close}
    style={{zIndex: 100}}
  >
    <Loader loading={briefStore.loading}>
      <div className="horizontal-flex-between" style={{height: "30px"}}>
        <Radio.Group value={brief.status} buttonStyle="solid" onChange={setStatus} size="small">
          <Radio.Button value="viewed">נצפה</Radio.Button>
          <Radio.Button value="bad">לא טוב</Radio.Button>
          <Radio.Button value="good">טוב</Radio.Button>
          <Radio.Button value="done">מוכן</Radio.Button>
          <Radio.Button value="ai_build">במ</Radio.Button>
          <Radio.Button value="ai_bad">במ רע</Radio.Button>
          <Radio.Button value="ai_good">במ טוב</Radio.Button>
        </Radio.Group>
        <Switcher vals={[briefStore.dirty, (brief.claims?.length || brief.responses?.length || brief.raw?.length), true]}>
          <Button loading={briefStore.btnLoading} type="primary" onClick={briefStore.saveNow}>שמור</Button>
          <Alert style={{padding: "4px 8px"}} type="info" message="נשמר"/>
          <div />
        </Switcher>
      </div>
      <br/>
      <Input placeholder="נושא" onChange={setSubject} value={brief.subject}/>
      <br/>
      <Menu mode="horizontal" selectedKeys={[briefStore.edit]}
            onClick={setEdit}
            items={[{key: "raw", label: "חומר גלם"}, {key: "claims", label: "כתב טענות"}, {key: "responses", label: "כתב תגובה"}]}/>
      <br/>
      {selectors}
      <Input.TextArea id="brief-claims" rows={20} onChange={setText} value={briefStore.text} />
      <br/>
      <a href={"/briefs/" + brief.id} target="_blank">פתח</a>
    </Loader>
  </Drawer>
})

let SuperSummaryLine = observer(({setMode, markedLine}) => {
  let click = () => {
    if (window.getSelection().toString().length)
      return;

    ArticleStore.locate = markedLine.line;
    setMode("article");
  };

  return <div className="focus-section" onClick={click}>
    <MarkedLine prefix="best" markedLine={markedLine}/>
  </div>
});

let SuperSummary = observer(({setMode}) => {
  return <div className="mark-area">
    <QuoteCopy>
      {ArticleStore.bestParts.map(p => <SuperSummaryLine setMode={setMode} markedLine={p} key={p.id}/>)}
    </QuoteCopy>
  </div>
});

let Skira = observer(() => {
  let [data, setData] = useState();

  useEffect(() => {
    let load = async () => {
      setData((await axios.post('/tiks', {case_num: ArticleStore.article.case_num})).data);
      // setHtml(data.html);
    }

    load();
  }, []);

  // if (html)
  //   return <HtmlDiv html={html} />

  if (data)
    return <div className="article-body">
      <h2>
        <span>להלן סקירה של סוגיות המפתח שנדונו במסגרת </span>
        <span>{data.title || data.case_num}:</span>
      </h2>
      <ol className="skira">
        <ColorArea regexps={[new RegExp(data.case_num)]}>
          {(data.tik_parts || []).map((tp, i) => <li key={i}>
            <LineSection section={tp.text} padding="0.5rem 0.5rem 0 0.5rem"/>
            <div className="skira-link">
              <span>נלקח מ<span>{Searcher.link(tp.ref_case)}</span> {tp.ref_title}</span>
            </div>
          </li>)}
        </ColorArea>
      </ol>
    </div>

  return <Spin />
});

let RefsArea = observer(({children}) => {
  let nums = ArticleStore.ref_case_nums;

  if (!nums || !nums.length)
    return children;

  let rs = [new RegExp(nums.join("|"))];
  // let rs = nums.map(n => new RegExp(n));

  let click = e => {
    let el = e.target;
    if (el.nodeName.match(/mark/i) && !el.className.match(/mark-brief/)) {
      e.stopPropagation();
      Searcher.search(el.innerText);
    }
  }

  return <div className="refs" onClick={click}>
    <ColorArea regexps={rs}>
      {children}
    </ColorArea>
  </div>
});

let Refs = ({setMode}) => {
  let nums = ArticleStore.ref_case_nums;

  if (!nums.length)
    return null;

  let r = new RegExp(nums.join("|"));
  let lines = _.filter(ArticleStore.strParts, l => l.match(r));

  return <div>
    {lines.map((p,i) => <FocusLine setMode={setMode} sectionStr={p} key={i}>
      <RefsArea>
        {p}
      </RefsArea>
    </FocusLine>)}
  </div>
}

let Edit = ({id}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  let save = async ({summary, issues}) => {
    setLoading(true);

    await axios.put("/articles/" + ArticleStore.id, {summary, issues});

    setLoading(false);
  }

  return <div>
    <Form form={form} onFinish={save} layout="vertical" initialValues={{summary: ArticleStore.summary, issues: ArticleStore.issues}}>
      <Form.Item name="summary" label="כותרת (תימצות)">
        <Input size="large" />
      </Form.Item>
      <Form.Item name="issues" label="סוגיות (אחת בכל שורה)">
        <TextArea rows={6} size="large" />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        שמור
      </Button>
    </Form>
  </div>
};

const QuoteCopy = ({children}) => {
  if (briefStore.show) {
    let copy = e => {
      let text = TextSelection.get();
      let el = document.getElementById("brief-claims");
      if (!text.match(/ /))
        return;

      if (el.value.length)
        briefStore.set(el.value + "\n\n" + text);
      else
        briefStore.set(text);

      _.defer(() => TextSelection.clear());
      _.defer(() => el.scrollTop = el.scrollHeight, 100);
    }
    return <TextSelectionActions onSelection={copy}>
      {children}
    </TextSelectionActions>
  }

  let mmcopy = () => {
    let text = ArticleStore.asDoc().mm() + ' — "' + TextSelection.get() + '"';
    utils.copy(text, 'הציטוט הועתק');
    TextSelection.clear();
  }

  let copy = () => {
    utils.copy(TextSelection.get(), 'הציטוט הועתק');
    TextSelection.clear();
  }

  let actions = <Space divider="|">
    <Button type="secondary" onClick={copy}>העתק</Button>
    <Button type="primary" onClick={mmcopy}>העתק + מראה מקום</Button>
  </Space>

  return <TextSelectionActions actions={actions}>
    {children}
  </TextSelectionActions>
}

let Suggestion = observer(({setTerm, suggestion}) => {
  let onclick = () => setTerm(suggestion);

  return <a style={{display: 'inline-block', padding: "0.5rem"}} onClick={onclick}>{suggestion}</a>;
});

let FocusLine = observer(({section, sectionStr, setMode, children}) => {
  let click = () => {
    if (window.getSelection().toString().length)
      return;

    ArticleStore.locate = section || sectionStr.replace(/<\/?[^>]+(>|$)/g, "");
    setMode("article");
  };

  return <div className="focus-section" onClick={click}>
    {section || children || sectionStr}
  </div>
});

export const focusStore = observable({
  term: "",
  loading: false,
  filterMax: true,
  markedLines: [],
  toggleFilterMax: () => {
    focusStore.filterMax = !focusStore.filterMax;
    focusStore.loading = true;
    focusStore.loadLines();
  },
  setTerm: term => {
    if (term === focusStore.term)
      return;

    focusStore.term = term;
    if (!ArticleStore.id)
      return;

    focusStore.loading = true;
    focusStore.loadLines();
  },
  loadLines: _.debounce(async () => {
    let term = focusStore.term;
    if (term.length) {
      focusStore.markedLines = await ArticleStore.markedLines(term, focusStore.filterMax ? "all" : "any")
    }

    focusStore.loading = false;
  }, 500),
});

let Chat = observer(({setMode, dev}) => {
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);

  let [form] = useForm();

  let search = async (params) => {
    setLoading(true);
    setResult(false);
    callWithCatch({
      url: `/searches/chat`,
      method: 'post',
      params: { doc_id: ArticleStore.id, ...params },
      onErr: () => {
        setLoading(false)
      },
      onSuccess: data => {
        let sub;
        if (data.text) {
          setLoading(false);
          setResult(data)
        }

        if (data.sub_to)
          sub = MyWS(data.sub_to, r => {
            setResult(r)
            if (r.done) {
              setLoading(false);
              sub.unsubscribe();
            }
          })
      }
    })
  }

  let qs = [
    [
      "סיכומים",
      "מה הרציו?",
      "אילו סעיפי חוק ותקנות אוזכרו?",
      "אילו הליכים משפטיים אחרים אוזכרו?",
      "האם ההליך התקבל או נדחה ומה הנימוקים לכך?"
    ],
    [
      "טענות",
      "מה היו טענות הצדדים?",
      "אילו טענות התקבלו ואילו נדחו?",
      "אילו טענות ניתן לטעון בערעור?",
    ]
  ]

  return <div className="space-y-4">
    <Form form={form} onFinish={search} layout="vertical" initialValues={{}}>
      {/*<Alert message={"זהו פיילוט של צ׳אט שנותן תשובות מפסק הדין הזה (לא צ׳אט כללי)".replace(/הזה/, ArticleStore.caseNum)} />*/}
      {/*<br/>*/}
      <div className="flex flex-col sm:flex-row gap-8">
        <div className="w-full sm:w-[300px] sm:flex-shrink-0 space-y-4">
          <div className="font-bold">ניתוח AI - הצעות לשאלות:</div>
          {
            qs.map(g => {
              let [title, ...rest] = g;
              return <div key={title}>
                <div>{title}</div>
                {rest.map(q => <div key={q}>{loading ? q : <a onClick={() => search({q})}>{q}</a>}</div>)}
              </div>
            })
          }
          <div>
            <div>שאלה חופשית:</div>
            <Form.Item name="q" label="">
              <Input.TextArea disabled={loading} rows={2} style={{width: "500px"}} autoFocus  placeholder="מה רצית לשאול?" onPressEnter={() => form.submit()}/>
            </Form.Item>
            <Button size="small" type="primary" loading={loading} htmlType="submit">שאלו את פסק הדין</Button>
          </div>
        </div>
        <div className="w-full sm:flex-grow">
          {result &&
            <div style={{fontSize: "16px", lineHeight: "28px", whiteSpace: "pre-line"}} className="md:shadow-md md:p-2">
              <div>
                <Space><Bot /><span>תשובה:</span></Space>
              </div>
              <HtmlDiv html={result.text} />
              <br/>
              {result.done && <div style={{fontSize: "10px", color: "#9CA3AF"}}>סיכום הAI בבטא ויכול לעשות טעויות. אנא ודאו את תשובותיו עם הפסיקה.</div>}
            </div> }
          {loading && !result && <div className="md:p-2"><Space><Bot /><Spin /></Space></div> }
        </div>
      </div>
    </Form>
  </div>
});

let EmbTest = observer(({setMode, dev}) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);

  let [form] = useForm();

  let search = async (params) => {
    setResult(null)
    callWithCatch({
      url: `/searches/chat`,
      method: 'post',
      params: { emb: true, doc_id: ArticleStore.id, ...params },
      setLoading,
      onSuccess: data => {
        pp(data)
        setResult(data.result);
      }
    })
  }

  return <div className="space-y-4">
    <Form form={form} onFinish={search} layout="vertical" initialValues={{q: (import.meta.env.DEV ? "משרה מלאה" : "")}}>
      <Form.Item name="q" label="">
        <Input.TextArea rows={2} style={{width: "500px"}} autoFocus />
      </Form.Item>
      <Button type="primary" loading={loading} htmlType="submit">בדוק</Button>
    </Form>
    <br/>
    {result &&
      <div style={{fontSize: "16px", lineHeight: "28px", whiteSpace: "pre-line"}}  className="mark-focus">
        {result.map(c => {
          let q = <Popover content={<div style={{width: "300px"}}>{c.q}</div>}><a>שאלה</a></Popover>

          return <Card size="small"  title={q} extra={c.distance} style={{marginBottom: "1rem"}}>
            {[c.chunk].map(pr => <p><HtmlDiv html={pr} /></p>)}
          </Card>
        })}
      </div> }
  </div>
});

let Focus = observer(({setMode, dev}) => {
  useEffect(() => {
    if (!focusStore.term.length)
      return;

    focusStore.loading = true;
    focusStore.loadLines();
  }, []);

  let onChange = async e => {
    let q = e.target.value;

    focusStore.setTerm(q)
  };

  let toggleFilterMax = () => focusStore.toggleFilterMax();

  let clear = () => {
    focusStore.setTerm("");
    document.getElementById("focus-input").focus();
  }

  let suffix = <Space>
    <Button size="small" onClick={clear}>נקה</Button>
  </Space>

  let suggestions = [
    "חוקים",
    "תקנות",
    "הלכה",
  ]

  if (admin)
    suggestions = suggestions.concat(
      "טענה",
      "חקירה נגדית",
    );

  let result;
  let matcher;
  let results = [];

  if (!ArticleStore.id || focusStore.loading)
    result = <Spin />;
  else
    if (focusStore.markedLines.length)
      result = <div className="mark-focus">
        {focusStore.markedLines.map((s, i) => <FocusLine setMode={setMode} sectionStr={s} key={i}>
          <HtmlDiv html={s} />
        </FocusLine>)}
      </div>

  if (!result)
    result = <Alert style={{width: "100%"}}
                    message="אין תוצאות"
                    type="warning"
    />

  if (!focusStore.term.length)
    result = null;

  return <div className="mark-focus">
    <Input id="focus-input" autoFocus={true} onChange={onChange} value={focusStore.term} suffix={suffix}/>
    <div className="horizontal-flex-between" style={{fontSize: "13px", marginBottom: "0.5rem"}}>
      <div>{suggestions.map(s => <Suggestion setTerm={focusStore.setTerm} suggestion={s} key={s}/>)}</div>
      {matcher && matcher.matched() && <div className="results-num" style={{marginTop: "0.25rem"}}>{results.length} תוצאות</div>}
    </div>
    <QuoteCopy>{result}</QuoteCopy>
  </div>
});

let Article = observer(({}) => {
  let article = ArticleStore.article;
  let skira = article && article.kind === 2;

  let inner;
  if (ArticleStore.html)
    inner = <HtmlDiv className="article-body" html={ArticleStore.html} />;
  else
    inner = <div className="article-body">
      {(ArticleStore.articleLines || []).map((s, i) => <LineSection section={s} key={i}/>)}
    </div>

  if (briefStore.show)
    inner = <ColorArea posfix="brief" regexps={[new RegExp(gon.claimRegexStr)]}>
      <ColorArea posfix="brief-period" regexps={[/[;.](?=\s)/]}>
        {inner}
      </ColorArea>
    </ColorArea>

  return <div tabIndex="1">
    {!skira && <div style={{textAlign: "center", direction: "rtl", marginBottom: "1rem"}}>
      <MMPop doc={ArticleStore.asDoc()} placement="right" forceLink={true}>
        <b>{article.short_case_type} {article.case_num} {article.court_name}</b>
      </MMPop>
    </div>}
    <RefsArea>
      <QuoteCopy>{inner}</QuoteCopy>
    </RefsArea>
  </div>;
});