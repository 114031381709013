import React, {useEffect, useState} from "react";

const Popover = ({x, y, actions}) => {
  return <div className="ant-popover ant-popover-placement-top"
              style={{position: 'fixed', top: y-80, left: Math.max(x-80, 0), zIndex: 900}}>
    <div className="ant-popover-content">
      <div className="ant-popover-arrow"><span className="ant-popover-arrow-content"></span></div>
      <div className="ant-popover-inner" role="tooltip">
        <div className="ant-popover-inner-content">
          {actions}
        </div>
      </div>
    </div>
  </div>
}

export default ({children, actions, onSelection}) => {
  let [show, setShow] = useState(false);
  let [x, setX] = useState(false);
  let [y, setY] = useState(false);

  let getText = () => window.getSelection().toString().trim();

  let onselectionchange = () => {
    if (!getText().length)
      setShow(false);
  };

  useEffect(() => {
    document.addEventListener('selectionchange', onselectionchange)

    return () => {
      document.removeEventListener('selectionchange', onselectionchange);
    };
  });

  let up = e => {
    let text = getText();

    if (text.length) {
      if (onSelection) {
        onSelection(e);
        return false;
      }

      setX(e.clientX);
      setY(e.clientY);
      setShow(true);
    }
  }

  return <div onMouseUp={up}>
    {show && <Popover x={x} y={y} actions={actions}/>}
    {children}
  </div>
}
