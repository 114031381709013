let prod = [
  "תיק אזרחי בסדר דין רגיל (ת\"א)",
  "תיק אזרחי בסדר דין מהיר (תא\"מ)",
  "סכסוך עבודה בסמכות שופט (סע\"ש)",
  "תיק פלילי (ת\"פ)",
  "תובענה ייצוגית (ת\"צ)",
  "תביעה קטנה (ת\"ק)",
  "עתירה מנהלית (עת\"מ)",
  "ביטוח לאומי (ב\"ל)",
  "רשות ערעור אזרחי (רע\"א)",
  "ערעור אזרחי (ע\"א)",
  "המרצת פתיחה (ה\"פ)",
  "בג\"ץ (בג\"ץ)",
  "ערעור פלילי (ע\"פ)",
  "מעצר עד תום ההליכים (מ\"ת)",
  "פשיטת רגל (פש\"ר)",
  "ערעור עבודה (ע\"ע)",
  "בש\"פ (בש\"פ)",
  "תיק אזרחי בסדר דין מקוצר (תא\"ק)",
  "תיק תעבורה (תת\"ע)",
  "פירוקים (פר\"ק)",
  "ערעור מנהלי (עמ\"נ)",
  "הארכת מועד להישפט (המ\"ש)",
  "תיק פשעים חמורים (תפ\"ח)",
  "המרצת פתיחה בוררות (הפ\"ב)",
  "בקשת רשות ערעור (בר\"ע)",
  "ערעור שונה - אזרחי (עש\"א)",
  "תכנון ובנייה - ועדות מקומיות (תו\"ב)",
  "תאונת דרכים (ת\"ד)",
  "התנגדות לביצוע שטר (ת\"ט)",
  "ביטול/עיכוב צו הריסה מינהלי (בצה\"מ)",
  "עע\"מ (עע\"מ)",
  "רשות ערעור תביעות קטנות (רת\"ק)",
  "פסק דין הצהרתי - כללי (פ\"ה)",
  "ועדת ערר (ו\"ע)",
  "קופת גמל (ק\"ג)",
  "חדלות פירעון (חדל\"פ)",
  "החזרה/טיפול בתפוס",
  "חדלות פירעון תאגיד (חדל\"ת)",
  "בקשות בנייה (בב\"נ)",
  "סכסוך עבודה (ס\"ע)",
  "בקשה לביטול איסור שימוש ברכב (בא\"ש)",
  "גרימת מוות בנהיגה רשלנית (גמ\"ר)",
  "תיק אזרחי דיון מהיר (תאד\"מ)",
  "דיון מהיר (ד\"מ)",
  "רע\"פ (רע\"פ)",
  "ערעור פלילי גזר דין (עפ\"ג)",
  "בקשה לעיון בחומר חקירה (בע\"ח)",
  "ערעור ביטוח לאומי (עב\"ל)",
  "עבירות שאינן תאונות דרכים ואינן דו\"חות (פ\"ל)",
  "סכסוך קיבוצי (ס\"ק)",
  "תיק אזרחי בסדר דין מיוחד (תא\"ח)",
  "בקשה לביטול - פסילה מנהלית (בפ\"מ)",
  "תיק פ\"ל אדום (פל\"א)",
  "בקשה לפסילה עד תום ההליכים (בפ\"ת)",
  "בר\"מ (בר\"מ)",
  "תביעה נגזרת (תנ\"ג)",
  "ערר מ\"ת (עמ\"ת)",
  "ערעור על החלטת רשם (ע\"ר)",
  "ערעור פלילי אחר (עפ\"א)",
  "רשות ערעור על רשם ההוצאה לפועל (רער\"צ)",
  "תפ - עובדים זרים (הע\"ז)",
  "בע\"מ (בע\"מ)",
  "ערר אחר (ע\"ח)",
  "קובלנה פלילית (ק\"פ)",
  "תיק הסגרה (תה\"ג)",
  "דנג\"ץ (דנג\"ץ)",
  "רשות ערעור על פסק בוררות (רע\"ב)",
  "ערעור על בית דין למשמעת (עב\"י)",
  "ערעור פלילי תעבורה (עפ\"ת)",
  "צו הריסה ללא הרשעה (212) (צ\"ה)",
  "ערעור פלילי על בימ\"ש לעניינים מקומיים (עפמ\"ק)",
  "ביצוע תביעה בהוצאה לפועל (ת\"ת)",
  "ערעור לפי חוק הנכים (ע\"נ)",
  "דנ\"א (דנ\"א)",
  "עתירות אסירים (עת\"א)",
  "חנייה (ח\"נ)",
  "ערעור תיק פלילי בניה (עתפ\"ב)",
  "מ\"ח (מ\"ח)",
  "תיק קנס חירום (תק\"ח)",
  "הטרדה מאיימת וצו הגנה (ה\"ט)",
  "צו עשה/צו מניעה (קבועים) (צ\"ו)",
  "ערעור על פי חוק (על\"ח)",
  "דנ\"מ (דנ\"מ)",
  "צו אחר (צ\"א)",
  "ערעור מסים (ע\"מ)",
  "עניינים מקומיים אחרים (עמ\"א)",
  "א\"ב (א\"ב)",
  "ערעור סכסוך קיבוצי (עס\"ק)",
  "רישוי עסקים (רע\"ס)",
  "בקשות עירייה אחרות (בע\"א)",
  "מעצר ימים (מ\"י)",
  "תביעת פינוי מושכר (תפ\"מ)",
  "תיק תת\"ע אדום (תתע\"א)",
  "שכירות (ש)",
  "תיק רבני (רבני)",
  "רשות ערעור על החלטת ראש הוצל\"פ (רע\"צ)",
  "תביעות בוררות (ת\"ב)",
  "חוק ביטוח בריאות ממלכתי (חב\"ר)",
  "ביטול צו הריסה מינהלי (בצה\"מ)",
  "ערעור חדלות פירעון (עחדל\"פ)",
  "בש\"א (בש\"א)",
  "ערעור פסלות שופט (עפ\"ס)",
  "בקשות רישוי (בר\"ש)",
  "תיק תחבורה (תת\"ח)",
  "דמי טיפול ארגוני (ד\"ט)",
  "חוקי עזר עירוניים - ברירת משפט (חע\"מ)",
  "תובענה מנהלית (ת\"מ)",
  "דנ\"פ (דנ\"פ)",
  "תכנון ובנייה - ועדות מחוזיות (תו\"ח)",
  "רשות ערעור על הוצאה לפועל (רצ\"פ)",
  "ערר מ\"י (עמ\"י)",
  "סכסוך קיבוצי כללי (סק\"כ)",
  "בקשה לשחרור בערובה (בש\"ע)",
  "תובענה ארגונית (בין עובד לארגון עובדים) (תע\"א)",
  "בקשה לאישור עיקול (בע\"ק)",
  "בש\"מ (בש\"מ)",
  "ערעור קופות גמל (עק\"ג)",
  "ערר על פי חוק המים (עח\"ק)",
  "צו מניעה - סגירה מנהלית לעסק (צ\"מ)",
  "רשות ערעור מנהלי (רע\"מ)",
  "תאונת דרכים ללא נפגעי גוף (נ\"ב)",
  "תיק פלילי בניה (תפ\"ב)",
  "עמ\"מ (עמ\"מ)",
  "ערעור על ועדה (ע\"ו)",
  "ערעור על קובלנה פלילית ע\"י הקובל (עק\"פ)",
  "בקשה לתשלום תכוף (בת\"ת)",
  "העברת מקום דיון/איחוד תיקים (המ\"ד)",
  "בר\"ש (בר\"ש)",
  "ע\"ב (ע\"ב)",
  "רשות ערעור חדלות פירעון (רחד\"פ)",
  "ערעור על החלטת שר העבודה והרווחה (עש\"ר)",
  "ערעור תובענה ייצוגית (עת\"צ)",
  "בקשה לשחזור תיק (בש\"ז)",
  "חוקי עזר עירוניים - ברירת קנס (חע\"ק)",
  "חיקור דין אזרחי (חד\"א)",
  "ערר על החלטת קצין (ע\"ק)",
  "תיק נ\"ב אדום (נב\"א)",
  "בקשה להארכת פסילה מנהלית (הפ\"מ)",
  "מבנים מסוכנים (מב\"ס)",
  "תביעות בין ארגוניות (סב\"א)",
  "תביעת חפצא (ת\"ח)",
  "בקשה לנשיאת מאסר בישראל (נמ\"ב)",
  "פיקוח וחוקים אחרים (פק\"ח)",
  "רשות ערעור על החלטת רשם המרכז (רע\"ר)",
  "רשות ערעור פלילי (רע\"פ)",
  "תביעה ממוכנת בסדר דין רגיל (תמ\"ר)",
  "תביעות לפי חוק הגזזת (גז\"ז)",
  "תיק לאיתור (תל\"א)",
  "תיק לאיתור פלילי (תל\"פ)",
  "תיק פלילי קהילתי  (תפ\"ק)"]

let all = [["אבעיה", "אב\"ע"],
  ["איחוד תובענות", "א\"ת"],
  ["איחוד תיקים פליליים", "את\"פ"],
  ["אימוץ", "אמ\"ץ"],
  ["איסור פרסום", "פ\"ר"],
  ["אכיפת פסק חוץ", "אפ\"ח"],
  ["אפוטרופסות", "א\"פ"],
  ["ביטוח לאומי", "ב\"ל"],
  ["ביטול קנס לפי חוק ועדות חקירה", "וח\"ק"],
  ["ביטול קנס מנהלי", "בק\"מ"],
  ["ביצוע תביעה בהוצאה לפועל", "ת\"ת"],
  ["בית דין מיוחד", "ביד\"מ"],
  ["בית דין משמעתי לשופטים", "בדמ\"ש"],
  ["בקשה לאישור עיקול", "בע\"ק"],
  ["בקשה לביטול - פסילה מנהלית/איסור שימוש ברכב", "בפ\"מ"],
  ["בקשה לביטול עונש שהוטל בשל הפרעה", "עה\"פ"],
  ["בקשה להתרת נישואין", "בה\"ן"],
  ["בקשה לפסילה עד תום ההליכים", "בפ\"ת"],
  ["בקשה לשחרור בערובה", "בש\"ע"],
  ["בקשה לתשלום תכוף", "בת\"ת"],
  ["בקשות בנייה", "בב\"נ"],
  ["בקשות עירייה אחרות", "בע\"א"],
  ["בקשות רישוי", "בר\"ש"],
  ["בקשת רשות ערעור", "בר\"ע"],
  ["בקשת שופט בעל דין בהליך אזרחי", "שב\"ד"],
  ["בקשת שופט נאשם", "שנ\"א"],
  ["גרימת מוות בנהיגה רשלנית", "גמ\"ר"],
  ["דיון מהיר בסמכות רשם", "דמ\"ר"],
  ["דיון מהיר בסמכות שופט", "דמ\"ש"],
  ["דיון נוסף אזרחי", "דנ\"א"],
  ["דיון נוסף בג\"ץ", "דנג\"ץ"],
  ["דיון נוסף מנהלי", "דנ\"מ"],
  ["דיון נוסף פלילי", "דנ\"פ"],
  ["דמי טיפול ארגוני", "ד\"ט"],
  ["האזנת סתר", "הס\"ת"],
  ["הארכת מעצר", "המ\"ע"],
  ["הגבלים עסקיים", "ה\"כ"],
  ["החזרת תפוס", "ה\"ת"],
  ["הטרדה מאיימת וצו הגנה", "ה\"ט"],
  ["היתר נישואין", "ה\"נ"],
  ["המרצת פתיחה", "ה\"פ"],
  ["המרצת פתיחה בוררות", "הפ\"ב"],
  ["העברת מקום דיון - פלילי", "הד\"פ"],
  ["העברת מקום דיון", "ה\"ד"],
  ["התנגדות לביצוע שטר", "ת\"ט"],
  ["התרת נישואין", "תה\"ן"],
  ["ועדת ערר", "ו\"ע"],
  ["חוזה אחיד", "ח\"א"],
  ["חוק ביטוח בריאות ממלכתי", "חב\"ר"],
  ["חוקי עזר עירוניים - ברירת משפט", "חע\"מ"],
  ["חוקי עזר עירוניים - ברירת קנס", "חע\"ק"],
  ["חיקור דין", "ח\"ד"],
  ["חנייה", "ח\"נ"],
  ["חקירת סיבות מוות", "חס\"מ"],
  ["יישוב סכסוך", "י\"ס"],
  ["כפיית ציות בית דין דתי", "כ\"צ"],
  ["מאסר - קנס מנהלי", "מק\"מ"],
  ["מעצר ימים", "מ\"י"],
  ["מעצר ימים בהעדר", "מי\"ב"],
  ["מעצר מנהלי", "מ\"מ"],
  ["מעצר עד תום ההליכים", "מ\"ת"],
  ["משפט חוזר", "מ\"ח"],
  ["ניהול רכושם של נעדרים או שבויים", "נע\"ד"],
  ["סכסוך עבודה", "ס\"ע"],
  ["סכסוך קיבוצי", "ס\"ק"],
  ["סכסוך קיבוצי כללי", "סק\"כ"],
  ["עבירות שאינן תאונות דרכים ואינן דו\"חות", "פ\"ל"],
  ["עניינים מקומיים אחרים", "עמ\"א"],
  ["ערעור אזרחי", "ע\"א"],
  ["ערעור בזיון בית משפט", "עב\"ז"],
  ["ערעור בחירות", "ע\"ב"],
  ["ערעור ביטוח לאומי", "עב\"ל"],
  ["ערעור חילוט רכוש", "עח\"ר"],
  ["ערעור לפי חוק הנכים", "ע\"נ"],
  ["ערעור לפי חוק הרשויות המקומיות (משמעת)", "ער\"מ"],
  ["ערעור לפי חוק חיילים שנספו במערכה", "עמ\"ח"],
  ["ערעור לשכת עורכי הדין", "על\"ע"],
  ["ערעור מנהלי", "עמ\"נ"],
  ["ערעור מסים", "ע\"מ"],
  ["ערעור מעצר מנהלי", "עמ\"מ"],
  ["ערעור משמעתי עובדי מדינה", "עש\"מ"],
  ["ערעור משפחה", "עמ\"ש"],
  ["ערעור נוער אזרחי", "ענ\"א"],
  ["ערעור נוער פלילי", "ענ\"פ"],
  ["ערעור נושאי משרה שלטונית", "ענמ\"ש"],
  ["ערעור סכסוך קיבוצי", "עס\"ק"],
  ["ערעור עבודה", "ע\"ע"],
  ["ערעור על בית דין למשמעת", "עב\"י"],
  ["ערעור על בית דין משמעתי של לשכת עורכי דין", "עמל\"ע"],
  ["ערעור על בית דין משמעתי של עובדי מדינה", "עמש\"מ"],
  ["ערעור על בית דין משמעתי של רשויות מקומיות", "עמר\"מ"],
  ["ערעור על החלטת רשם - פלילי", "ער\"פ"],
  ["ערעור על החלטת רשם", "ע\"ר"],
  ["ערעור על החלטת רשם המפלגות", "עמ\"פ"],
  ["ערעור על החלטת שר העבודה והרווחה", "עש\"ר"],
  ["ערעור על ועדה", "ע\"ו"],
  ["ערעור על פי חוק", "על\"ח"],
  ["ערעור על קובלנה פלילית ע\"י הנאשם", "עק\"נ"],
  ["ערעור על קובלנה פלילית ע\"י הקובל", "עק\"פ"],
  ["ערעור עתירה/תובענה מנהלית", "עע\"מ"],
  ["ערעור עתירת אסיר", "עעת\"א"],
  ["ערעור פלילי", "ע\"פ"],
  ["ערעור פלילי אחר", "עפ\"א"],
  ["ערעור פלילי גזר דין", "עפ\"ג"],
  ["ערעור פלילי רצח", "עפ\"ר"],
  ["ערעור פלילי תעבורה", "עפ\"ת"],
  ["ערעור פסלות שופט - פלילי", "עפס\"פ"],
  ["ערעור פסלות שופט", "עפ\"ס"],
  ["ערעור שונה - אזרחי", "עש\"א"],
  ["ערעור שונה", "ע\"ש"],
  ["ערעור שירות התעסוקה", "עש\"ת"],
  ["ערר אחר", "ע\"ח"],
  ["ערר בדיקה פסיכיאטרית", "עב\"פ"],
  ["ערר בעניין איסור פרסום", "עא\"פ"],
  ["ערר בעניין חילוט ערבויות", "עח\"ע"],
  ["ערר בעניין עיון בראיות התביעה", "עע\"ר"],
  ["ערר בעניין פגישה עם עורך דין", "עפ\"ע"],
  ["ערר הגנת הציבור מפני עבריני מין", "עה\"ג"],
  ["ערר מ\"י", "עמ\"י"],
  ["ערר מ\"ת", "עמ\"ת"],
  ["ערר עיכוב ביצוע", "עכ\"ב"],
  ["ערר על גובה קנס מנהלי", "עק\"מ"],
  ["ערר על פי חוק המים", "עח\"ק"],
  ["ערר על פסילה מנהלית", "עפ\"מ"],
  ["ערר על פסילת רשיון", "עפ\"ן"],
  ["עתירה לבג\"ץ", "בג\"ץ"],
  ["עתירה לגילוי ראיה", "עג\"ר"],
  ["עתירה מנהלית", "עת\"מ"],
  ["עתירות אסירים", "עת\"א"],
  ["פיקוח וחוקים אחרים", "פק\"ח"],
  ["פירוקים", "פר\"ק"],
  ["פסק דין הצהרתי - כללי", "פ\"ה"],
  ["פשיטת רגל", "פש\"ר"],
  ["צו אחר", "צ\"א"],
  ["צו הריסה ללא הרשעה (212)", "צ\"ה"],
  ["צו חיפוש / צו כניסה", "צ\"ח"],
  ["צו מעצר כללי", "מ\"כ"],
  ["צו עשה/צו מניעה (קבועים)", "צ\"ו"],
  ["קובלנה פלילית", "ק\"פ"],
  ["קופת גמל", "ק\"ג"],
  ["רישוי עסקים", "רע\"ס"],
  ["רשות ערעור אזרחי", "רע\"א"],
  ["רשות ערעור מנהלי", "רע\"מ"],
  ["רשות ערעור משפחה", "רמ\"ש"],
  ["רשות ערעור על הוצאה לפועל", "רצ\"פ"],
  ["רשות ערעור על החלטת ראש הוצל\"פ", "רע\"צ"],
  ["רשות ערעור על ועדה", "רע\"ו"],
  ["רשות ערעור על פסק בוררות", "רע\"ב"],
  ["רשות ערעור עתירת אסיר", "רעת\"א"],
  ["רשות ערעור פלילי", "רע\"פ"],
  ["רשות ערעור שונה", "רע\"ש"],
  ["רשות ערעור תביעות קטנות", "רת\"ק"],
  ["שכירות (ש) ש שינוי שם", "ש\"ש"],
  ["שכר עבודה בסמכות רשם", "ש\"ע"],
  ["תאונת דרכים", "ת\"ד"],
  ["תאונת דרכים ללא נפגעי גוף", "נ\"ב"],
  ["תביעה ממוכנת בסדר דין מקוצר", "תמ\"ק"],
  ["תביעה ממוכנת בסדר דין רגיל", "תמ\"ר"],
  ["תביעה נגזרת", "תנ\"ג"],
  ["תביעה קטנה", "ת\"ק"],
  ["תביעות בוררות", "ת\"ב"],
  ["תביעות בין ארגוניות", "סב\"א"],
  ["תביעות לפי חוק הגזזת", "גז\"ז"],
  ["תביעות מכח חוקים שונים", "ח\"ש"],
  ["תביעת גברא", "תג\"א"],
  ["תביעת חפצא", "ת\"ח"],
  ["תביעת נזקקות", "תנ\"ז"],
  ["תובענה ארגונית (בין עובד לארגון עובדים)", "תע\"א"],
  ["תובענה ייצוגית", "ת\"צ"],
  ["תובענה מנהלית", "ת\"מ"],
  ["תיק אזרחי בסדר דין מהיר", "תא\"מ"],
  ["תיק אזרחי בסדר דין מיוחד", "תא\"ח"],
  ["תיק אזרחי בסדר דין מקוצר", "תא\"ק"],
  ["תיק אזרחי בסדר דין רגיל", "ת\"א"],
  ["תיק הסגרה", "תה\"ג"],
  ["תיק הסכם", "תה\"ס"],
  ["תיק כספי - עליון", "ע\"ל"],
  ["תיק לאיתור", "תל\"א"],
  ["תיק לאיתור בג\"צ", "תל\"ב"],
  ["תיק לאיתור פלילי", "תל\"פ"],
  ["תיק משפחה", "תמ\"ש"],
  ["תיק עזבונות", "ת\"ע"],
  ["תיק פלילי", "ת\"פ"],
  ["תיק פשעים חמורים", "תפ\"ח"],
  ["תיק קביעת גיל", "ת\"ג"],
  ["תיק תחבורה", "תת\"ח"],
  ["תיק תעבורה", "תת\"ע"],
  ["תכנון ובנייה - ועדות מחוזיות", "תו\"ח"],
  ["תכנון ובנייה - ועדות מקומיות", "תו\"ב"],
  ["תפ - הארכת מועד להישפט", "המ\"ש"],
  ["תפ - עובדים זרים", "הע\"ז"],
  ["תפ - שכר מינימום", "ש\"מ"],
  ["תפ - שעות עבודה ומנוחה", "שע\"מ"]];

let typeMap = {
  "ר\"ע":{"title":"רשות ערעור","sym":"ר\"ע","algolia":"רשות ערעור (ר\"ע)"},
  "ב\"ש":{"title":"בקשות שונות","sym":"ב\"ש","algolia":"בקשות שונות (ב\"ש)"},
  "ענ\"א":{"title":"ערעור נוער אזרחי","sym":"ענ\"א","algolia":"ערעור נוער אזרחי (ענ\"א)"},
  "ענ\"פ":{"title":"ערעור נוער פלילי","sym":"ענ\"פ","algolia":"ערעור נוער פלילי (ענ\"פ)"},
  "פע\"מ":{"title":"פיקוח עברייני מין","sym":"פע\"מ","algolia":"פיקוח עברייני מין (פע\"מ)"},
  "תפח\"ע":{"title":"תיק פלילי פשע חמור עדות קטין נפגע עבירה","sym":"תפח\"ע","algolia":"תיק פלילי פשע חמור עדות קטין נפגע עבירה (תפח\"ע)"},
  "תפ\"ע":{"title":"תיק פלילי עדות קטין נפגע עבירה","sym":"תפ\"ע","algolia":"תיק פלילי עדות קטין נפגע עבירה (תפ\"ע)"},
  "דב\"ע":{"title":"דיון בבית הדין הארצי לעבודה","sym":"דב\"ע","algolia":"דיון בבית הדין הארצי לעבודה (דב\"ע)"},
  "פ": {"title": "אפוטרופסות", "sym": "א\"פ", "algolia": "א\"פ (א\"פ)"},
  "אפ\"ח": {"title": "אכיפת פסק חוץ", "sym": "אפ\"ח", "algolia": "אפ\"ח (אפ\"ח)"},
  "ה\"נ": {"title": "היתר נישואין", "sym": "ה\"נ", "algolia": "ה\"נ (ה\"נ)"},
  "י\"ס": {"title": "יישוב סכסוך", "sym": "י\"ס", "algolia": "י\"ס (י\"ס)"},
  "עמ\"ש": {"title": "ערעור משפחה", "sym": "עמ\"ש", "algolia": "עמ\"ש (עמ\"ש)"},
  "צב\"נ": {"title": "צו ביניים לנזקקות", "sym": "צב\"נ", "algolia": "צב\"נ (צב\"נ)"},
  "רמ\"ש": {"title": "רשות ערעור משפחה", "sym": "רמ\"ש", "algolia": "רמ\"ש (רמ\"ש)"},
  "ש\"ש": {"title": "שינוי שם", "sym": "ש\"ש", "algolia": "ש\"ש (ש\"ש)"},
  "ת\"ג": {"title": "תיק קביעת גיל", "sym": "ת\"ג", "algolia": "ת\"ג (ת\"ג)"},
  "ת\"ע": {"title": "תיק עזבונות", "sym": "ת\"ע", "algolia": "ת\"ע (ת\"ע)"},
  "תה\"ס": {"title": "תיק הסכם", "sym": "תה\"ס", "algolia": "תה\"ס (תה\"ס)"},
  "תלה\"מ": {"title": "תביעה לאחר הסדר התדיינות", "sym": "תלה\"מ", "algolia": "תלה\"מ (תלה\"מ)"},
  "תמ\"ש": {"title": "תיק משפחה", "sym": "תמ\"ש", "algolia": "תמ\"ש (תמ\"ש)"},
  "תמה\"ח": {"title": "מינוי תומך החלטות", "sym": "תמה\"ח", "algolia": "תמה\"ח (תמה\"ח)"},
  "תנ\"ז": {"title": "תביעת נזקקות", "sym": "תנ\"ז", "algolia": "תנ\"ז (תנ\"ז)"},
  "ת\"א": {
    "title": "תיק אזרחי בסדר דין רגיל",
    "sym": "ת\"א",
    "algolia": "תיק אזרחי בסדר דין רגיל (ת\"א)"
  },
  "תא\"מ": {
    "title": "תיק אזרחי בסדר דין מהיר",
    "sym": "תא\"מ",
    "algolia": "תיק אזרחי בסדר דין מהיר (תא\"מ)"
  },
  "סע\"ש": {
    "sym": "סע\"ש",
    "title": "סכסוך עבודה בסמכות שופט ",
    "algolia": "סכסוך עבודה בסמכות שופט (סע\"ש)"
  },
  "ת\"פ": {
    "title": "תיק פלילי",
    "sym": "ת\"פ",
    "algolia": "תיק פלילי (ת\"פ)"
  },
  "ת\"צ": {
    "title": "תובענה ייצוגית",
    "sym": "ת\"צ",
    "algolia": "תובענה ייצוגית (ת\"צ)"
  },
  "ת\"ק": {
    "title": "תביעה קטנה",
    "sym": "ת\"ק",
    "algolia": "תביעה קטנה (ת\"ק)"
  },
  "עת\"מ": {
    "title": "עתירה מנהלית",
    "sym": "עת\"מ",
    "algolia": "עתירה מנהלית (עת\"מ)"
  },
  "ב\"ל": {
    "title": "ביטוח לאומי",
    "sym": "ב\"ל",
    "algolia": "ביטוח לאומי (ב\"ל)"
  },
  "רע\"א": {
    "title": "רשות ערעור אזרחי",
    "sym": "רע\"א",
    "algolia": "רשות ערעור אזרחי (רע\"א)"
  },
  "ע\"א": {
    "title": "ערעור אזרחי",
    "sym": "ע\"א",
    "algolia": "ערעור אזרחי (ע\"א)"
  },
  "ה\"פ": {
    "title": "המרצת פתיחה",
    "sym": "ה\"פ",
    "algolia": "המרצת פתיחה (ה\"פ)"
  },
  "בג\"ץ": {
    "title": "עתירה לבג\"ץ",
    "sym": "בג\"ץ",
    "algolia": "בג\"ץ (בג\"ץ)"
  },
  "ע\"פ": {
    "title": "ערעור פלילי",
    "sym": "ע\"פ",
    "algolia": "ערעור פלילי (ע\"פ)"
  },
  "מ\"ת": {
    "title": "מעצר עד תום ההליכים",
    "sym": "מ\"ת",
    "algolia": "מעצר עד תום ההליכים (מ\"ת)"
  },
  "פש\"ר": {
    "title": "פשיטת רגל",
    "sym": "פש\"ר",
    "algolia": "פשיטת רגל (פש\"ר)"
  },
  "ע\"ע": {
    "title": "ערעור עבודה",
    "sym": "ע\"ע",
    "algolia": "ערעור עבודה (ע\"ע)"
  },
  "בש\"פ": {
    "sym": "בש\"פ",
    "title": "בקשות שונות פלילי",
    "algolia": "בש\"פ (בש\"פ)"
  },
  "תא\"ק": {
    "title": "תיק אזרחי בסדר דין מקוצר",
    "sym": "תא\"ק",
    "algolia": "תיק אזרחי בסדר דין מקוצר (תא\"ק)"
  },
  "תת\"ע": {
    "title": "תיק תעבורה",
    "sym": "תת\"ע",
    "algolia": "תיק תעבורה (תת\"ע)"
  },
  "פר\"ק": {
    "title": "פירוקים",
    "sym": "פר\"ק",
    "algolia": "פירוקים (פר\"ק)"
  },
  "עמ\"נ": {
    "title": "ערעור מנהלי",
    "sym": "עמ\"נ",
    "algolia": "ערעור מנהלי (עמ\"נ)"
  },
  "המ\"ש": {
    "title": "תפ - הארכת מועד להישפט",
    "sym": "המ\"ש",
    "algolia": "הארכת מועד להישפט (המ\"ש)"
  },
  "תפ\"ח": {
    "title": "תיק פשעים חמורים",
    "sym": "תפ\"ח",
    "algolia": "תיק פשעים חמורים (תפ\"ח)"
  },
  "הפ\"ב": {
    "title": "המרצת פתיחה בוררות",
    "sym": "הפ\"ב",
    "algolia": "המרצת פתיחה בוררות (הפ\"ב)"
  },
  "בר\"ע": {
    "title": "בקשת רשות ערעור",
    "sym": "בר\"ע",
    "algolia": "בקשת רשות ערעור (בר\"ע)"
  },
  "עש\"א": {
    "title": "ערעור שונה - אזרחי",
    "sym": "עש\"א",
    "algolia": "ערעור שונה - אזרחי (עש\"א)"
  },
  "תו\"ב": {
    "title": "תכנון ובנייה - ועדות מקומיות",
    "sym": "תו\"ב",
    "algolia": "תכנון ובנייה - ועדות מקומיות (תו\"ב)"
  },
  "ת\"ד": {
    "title": "תאונת דרכים",
    "sym": "ת\"ד",
    "algolia": "תאונת דרכים (ת\"ד)"
  },
  "ת\"ט": {
    "title": "התנגדות לביצוע שטר",
    "sym": "ת\"ט",
    "algolia": "התנגדות לביצוע שטר (ת\"ט)"
  },
  "בצה\"מ": {
    "sym": "בצה\"מ",
    "title": "ביטול צו הריסה מינהלי ",
    "algolia": "ביטול צו הריסה מינהלי (בצה\"מ)"
  },
  "עע\"מ": {
    "title": "ערעור עתירה/תובענה מנהלית",
    "sym": "עע\"מ",
    "algolia": "עע\"מ (עע\"מ)"
  },
  "רת\"ק": {
    "title": "רשות ערעור תביעות קטנות",
    "sym": "רת\"ק",
    "algolia": "רשות ערעור תביעות קטנות (רת\"ק)"
  },
  "פ\"ה": {
    "title": "פסק דין הצהרתי - כללי",
    "sym": "פ\"ה",
    "algolia": "פסק דין הצהרתי - כללי (פ\"ה)"
  },
  "ו\"ע": {
    "title": "ועדת ערר",
    "sym": "ו\"ע",
    "algolia": "ועדת ערר (ו\"ע)"
  },
  "ק\"ג": {
    "title": "קופת גמל",
    "sym": "ק\"ג",
    "algolia": "קופת גמל (ק\"ג)"
  },
  "חדל\"פ": {
    "sym": "חדל\"פ",
    "title": "חדלות פירעון ",
    "algolia": "חדלות פירעון (חדל\"פ)"
  },
  "ה\"ת": {
    "title": "החזרה/טיפול בתפוס",
    "algolia": "החזרה/טיפול בתפוס",
    "sym": "ה\"ת"
  },
  "חדל\"ת": {
    "sym": "חדל\"ת",
    "title": "חדלות פירעון תאגיד ",
    "algolia": "חדלות פירעון תאגיד (חדל\"ת)"
  },
  "בב\"נ": {
    "title": "בקשות בנייה",
    "sym": "בב\"נ",
    "algolia": "בקשות בנייה (בב\"נ)"
  },
  "ס\"ע": {
    "title": "סכסוך עבודה",
    "sym": "ס\"ע",
    "algolia": "סכסוך עבודה (ס\"ע)"
  },
  "בא\"ש": {
    "sym": "בא\"ש",
    "title": "בקשה לביטול איסור שימוש ברכב ",
    "algolia": "בקשה לביטול איסור שימוש ברכב (בא\"ש)"
  },
  "גמ\"ר": {
    "title": "גרימת מוות בנהיגה רשלנית",
    "sym": "גמ\"ר",
    "algolia": "גרימת מוות בנהיגה רשלנית (גמ\"ר)"
  },
  "תאד\"מ": {
    "sym": "תאד\"מ",
    "title": "תיק אזרחי דיון מהיר ",
    "algolia": "תיק אזרחי דיון מהיר (תאד\"מ)"
  },
  "ד\"מ": {
    "sym": "ד\"מ",
    "title": "דיון מהיר ",
    "algolia": "דיון מהיר (ד\"מ)"
  },
  "רע\"פ": {
    "title": "רשות ערעור פלילי",
    "sym": "רע\"פ",
    "algolia": "רשות ערעור פלילי (רע\"פ)"
  },
  "עפ\"ג": {
    "title": "ערעור פלילי גזר דין",
    "sym": "עפ\"ג",
    "algolia": "ערעור פלילי גזר דין (עפ\"ג)"
  },
  "בע\"ח": {
    "sym": "בע\"ח",
    "title": "בקשה לעיון בחומר חקירה ",
    "algolia": "בקשה לעיון בחומר חקירה (בע\"ח)"
  },
  "עב\"ל": {
    "title": "ערעור ביטוח לאומי",
    "sym": "עב\"ל",
    "algolia": "ערעור ביטוח לאומי (עב\"ל)"
  },
  "פ\"ל": {
    "title": "עבירות שאינן תאונות דרכים ואינן דו\"חות",
    "sym": "פ\"ל",
    "algolia": "עבירות שאינן תאונות דרכים ואינן דו\"חות (פ\"ל)"
  },
  "ס\"ק": {
    "title": "סכסוך קיבוצי",
    "sym": "ס\"ק",
    "algolia": "סכסוך קיבוצי (ס\"ק)"
  },
  "תא\"ח": {
    "title": "תיק אזרחי בסדר דין מיוחד",
    "sym": "תא\"ח",
    "algolia": "תיק אזרחי בסדר דין מיוחד (תא\"ח)"
  },
  "בפ\"מ": {
    "title": "בקשה לביטול - פסילה מנהלית/איסור שימוש ברכב",
    "sym": "בפ\"מ",
    "algolia": "בקשה לביטול - פסילה מנהלית (בפ\"מ)"
  },
  "פל\"א": {
    "sym": "פל\"א",
    "title": "תיק פ\"ל אדום ",
    "algolia": "תיק פ\"ל אדום (פל\"א)"
  },
  "בפ\"ת": {
    "title": "בקשה לפסילה עד תום ההליכים",
    "sym": "בפ\"ת",
    "algolia": "בקשה לפסילה עד תום ההליכים (בפ\"ת)"
  },
  "בר\"מ": {
    "sym": "בר\"מ",
    "title": "בקשת רשות ערעור מנהלי",
    "algolia": "בר\"מ (בר\"מ)"
  },
  "תנ\"ג": {
    "title": "תביעה נגזרת",
    "sym": "תנ\"ג",
    "algolia": "תביעה נגזרת (תנ\"ג)"
  },
  "עמ\"ת": {
    "title": "ערר מעצר עד תום ההליכים",
    "sym": "עמ\"ת",
    "algolia": "ערר מ\"ת (עמ\"ת)"
  },
  "ע\"ר": {
    "title": "ערעור על החלטת רשם",
    "sym": "ע\"ר",
    "algolia": "ערעור על החלטת רשם (ע\"ר)"
  },
  "עפ\"א": {
    "title": "ערעור פלילי אחר",
    "sym": "עפ\"א",
    "algolia": "ערעור פלילי אחר (עפ\"א)"
  },
  "רער\"צ": {
    "sym": "רער\"צ",
    "title": "רשות ערעור על רשם ההוצאה לפועל ",
    "algolia": "רשות ערעור על רשם ההוצאה לפועל (רער\"צ)"
  },
  "הע\"ז": {
    "title": "תפ - עובדים זרים",
    "sym": "הע\"ז",
    "algolia": "תפ - עובדים זרים (הע\"ז)"
  },
  "בע\"מ": {
    "sym": "בע\"מ",
    "title": "בקשת ערעור משפחה",
    "algolia": "בע\"מ (בע\"מ)"
  },
  "ע\"ח": {
    "title": "ערר אחר",
    "sym": "ע\"ח",
    "algolia": "ערר אחר (ע\"ח)"
  },
  "ק\"פ": {
    "title": "קובלנה פלילית",
    "sym": "ק\"פ",
    "algolia": "קובלנה פלילית (ק\"פ)"
  },
  "תה\"ג": {
    "title": "תיק הסגרה",
    "sym": "תה\"ג",
    "algolia": "תיק הסגרה (תה\"ג)"
  },
  "דנג\"ץ": {
    "title": "דיון נוסף בג\"ץ",
    "sym": "דנג\"ץ",
    "algolia": "דנג\"ץ (דנג\"ץ)"
  },
  "רע\"ב": {
    "title": "רשות ערעור על פסק בוררות",
    "sym": "רע\"ב",
    "algolia": "רשות ערעור על פסק בוררות (רע\"ב)"
  },
  "עב\"י": {
    "title": "ערעור על בית דין למשמעת",
    "sym": "עב\"י",
    "algolia": "ערעור על בית דין למשמעת (עב\"י)"
  },
  "עפ\"ת": {
    "title": "ערעור פלילי תעבורה",
    "sym": "עפ\"ת",
    "algolia": "ערעור פלילי תעבורה (עפ\"ת)"
  },
  "צ\"ה": {
    "title": "צו הריסה ללא הרשעה (212)",
    "sym": "צ\"ה",
    "algolia": "צו הריסה ללא הרשעה (212) (צ\"ה)"
  },
  "עפמ\"ק": {
    "sym": "עפמ\"ק",
    "title": "ערעור פלילי על בימ\"ש לעניינים מקומיים ",
    "algolia": "ערעור פלילי על בימ\"ש לעניינים מקומיים (עפמ\"ק)"
  },
  "ת\"ת": {
    "title": "ביצוע תביעה בהוצאה לפועל",
    "sym": "ת\"ת",
    "algolia": "ביצוע תביעה בהוצאה לפועל (ת\"ת)"
  },
  "ע\"נ": {
    "title": "ערעור לפי חוק הנכים",
    "sym": "ע\"נ",
    "algolia": "ערעור לפי חוק הנכים (ע\"נ)"
  },
  "דנ\"א": {
    "title": "דיון נוסף אזרחי",
    "sym": "דנ\"א",
    "algolia": "דנ\"א (דנ\"א)"
  },
  "עת\"א": {
    "title": "עתירות אסירים",
    "sym": "עת\"א",
    "algolia": "עתירות אסירים (עת\"א)"
  },
  "ח\"נ": {
    "title": "חנייה",
    "sym": "ח\"נ",
    "algolia": "חנייה (ח\"נ)"
  },
  "עתפ\"ב": {
    "sym": "עתפ\"ב",
    "title": "ערעור תיק פלילי בניה ",
    "algolia": "ערעור תיק פלילי בניה (עתפ\"ב)"
  },
  "מ\"ח": {
    "title": "משפט חוזר",
    "sym": "מ\"ח",
    "algolia": "מ\"ח (מ\"ח)"
  },
  "תק\"ח": {
    "sym": "תק\"ח",
    "title": "תיק קנס חירום ",
    "algolia": "תיק קנס חירום (תק\"ח)"
  },
  "ה\"ט": {
    "title": "הטרדה מאיימת וצו הגנה",
    "sym": "ה\"ט",
    "algolia": "הטרדה מאיימת וצו הגנה (ה\"ט)"
  },
  "צ\"ו": {
    "title": "צו עשה/צו מניעה (קבועים)",
    "sym": "צ\"ו",
    "algolia": "צו עשה/צו מניעה (קבועים) (צ\"ו)"
  },
  "על\"ח": {
    "title": "ערעור על פי חוק",
    "sym": "על\"ח",
    "algolia": "ערעור על פי חוק (על\"ח)"
  },
  "דנ\"מ": {
    "title": "דיון נוסף מנהלי",
    "sym": "דנ\"מ",
    "algolia": "דנ\"מ (דנ\"מ)"
  },
  "צ\"א": {
    "title": "צו אחר",
    "sym": "צ\"א",
    "algolia": "צו אחר (צ\"א)"
  },
  "ע\"מ": {
    "title": "ערעור מסים",
    "sym": "ע\"מ",
    "algolia": "ערעור מסים (ע\"מ)"
  },
  "עמ\"א": {
    "title": "עניינים מקומיים אחרים",
    "sym": "עמ\"א",
    "algolia": "עניינים מקומיים אחרים (עמ\"א)"
  },
  "א\"ב": {
    "sym": "א\"ב",
    "title": "אישור בחירות ",
    "algolia": "א\"ב (א\"ב)"
  },
  "עס\"ק": {
    "title": "ערעור סכסוך קיבוצי",
    "sym": "עס\"ק",
    "algolia": "ערעור סכסוך קיבוצי (עס\"ק)"
  },
  "רע\"ס": {
    "title": "רישוי עסקים",
    "sym": "רע\"ס",
    "algolia": "רישוי עסקים (רע\"ס)"
  },
  "בע\"א": {
    "title": "בקשות עירייה אחרות",
    "sym": "בע\"א",
    "algolia": "בקשות עירייה אחרות (בע\"א)"
  },
  "מ\"י": {
    "title": "מעצר ימים",
    "sym": "מ\"י",
    "algolia": "מעצר ימים (מ\"י)"
  },
  "תפ\"מ": {
    "sym": "תפ\"מ",
    "title": "תביעת פינוי מושכר ",
    "algolia": "תביעת פינוי מושכר (תפ\"מ)"
  },
  "תתע\"א": {
    "sym": "תתע\"א",
    "title": "תיק תת\"ע אדום ",
    "algolia": "תיק תת\"ע אדום (תתע\"א)"
  },
  "ש": {
    "sym": "ש",
    "title": "שכירות ",
    "algolia": "שכירות (ש)"
  },
  "רע\"צ": {
    "title": "רשות ערעור על החלטת ראש הוצל\"פ",
    "sym": "רע\"צ",
    "algolia": "רשות ערעור על החלטת ראש הוצל\"פ (רע\"צ)"
  },
  "ת\"ב": {
    "title": "תביעות בוררות",
    "sym": "ת\"ב",
    "algolia": "תביעות בוררות (ת\"ב)"
  },
  "חב\"ר": {
    "title": "חוק ביטוח בריאות ממלכתי",
    "sym": "חב\"ר",
    "algolia": "חוק ביטוח בריאות ממלכתי (חב\"ר)"
  },
  "עחדל\"פ": {
    "sym": "עחדל\"פ",
    "title": "ערעור חדלות פירעון ",
    "algolia": "ערעור חדלות פירעון (עחדל\"פ)"
  },
  "בש\"א": {
    "sym": "בש\"א",
    "title": "בקשות שונות אזרחי",
    "algolia": "בש\"א (בש\"א)"
  },
  "עפ\"ס": {
    "title": "ערעור פסלות שופט",
    "sym": "עפ\"ס",
    "algolia": "ערעור פסלות שופט (עפ\"ס)"
  },
  "בר\"ש": {
    "title": "בקשות רישוי",
    "sym": "בר\"ש",
    "algolia": "בר\"ש (בר\"ש)"
  },
  "תת\"ח": {
    "title": "תיק תחבורה",
    "sym": "תת\"ח",
    "algolia": "תיק תחבורה (תת\"ח)"
  },
  "ד\"ט": {
    "title": "דמי טיפול ארגוני",
    "sym": "ד\"ט",
    "algolia": "דמי טיפול ארגוני (ד\"ט)"
  },
  "חע\"מ": {
    "title": "חוקי עזר עירוניים - ברירת משפט",
    "sym": "חע\"מ",
    "algolia": "חוקי עזר עירוניים - ברירת משפט (חע\"מ)"
  },
  "ת\"מ": {
    "title": "תובענה מנהלית",
    "sym": "ת\"מ",
    "algolia": "תובענה מנהלית (ת\"מ)"
  },
  "דנ\"פ": {
    "title": "דיון נוסף פלילי",
    "sym": "דנ\"פ",
    "algolia": "דנ\"פ (דנ\"פ)"
  },
  "תו\"ח": {
    "title": "תכנון ובנייה - ועדות מחוזיות",
    "sym": "תו\"ח",
    "algolia": "תכנון ובנייה - ועדות מחוזיות (תו\"ח)"
  },
  "רצ\"פ": {
    "title": "רשות ערעור על הוצאה לפועל",
    "sym": "רצ\"פ",
    "algolia": "רשות ערעור על הוצאה לפועל (רצ\"פ)"
  },
  "עמ\"י": {
    "title": "ערר מעצר ימים",
    "sym": "עמ\"י",
    "algolia": "ערר מ\"י (עמ\"י)"
  },
  "סק\"כ": {
    "title": "סכסוך קיבוצי כללי",
    "sym": "סק\"כ",
    "algolia": "סכסוך קיבוצי כללי (סק\"כ)"
  },
  "בש\"ע": {
    "title": "בקשה לשחרור בערובה",
    "sym": "בש\"ע",
    "algolia": "בקשה לשחרור בערובה (בש\"ע)"
  },
  "תע\"א": {
    "title": "תובענה ארגונית (בין עובד לארגון עובדים)",
    "sym": "תע\"א",
    "algolia": "תובענה ארגונית (בין עובד לארגון עובדים) (תע\"א)"
  },
  "בע\"ק": {
    "title": "בקשה לאישור עיקול",
    "sym": "בע\"ק",
    "algolia": "בקשה לאישור עיקול (בע\"ק)"
  },
  "בש\"מ": {
    "sym": "בש\"מ",
    "title": "בקשות שונות מנהלי",
    "algolia": "בש\"מ (בש\"מ)"
  },
  "עק\"ג": {
    "sym": "עק\"ג",
    "title": "ערעור קופות גמל ",
    "algolia": "ערעור קופות גמל (עק\"ג)"
  },
  "עח\"ק": {
    "title": "ערר על פי חוק המים",
    "sym": "עח\"ק",
    "algolia": "ערר על פי חוק המים (עח\"ק)"
  },
  "צ\"מ": {
    "sym": "צ\"מ",
    "title": "צו מניעה - סגירה מנהלית לעסק ",
    "algolia": "צו מניעה - סגירה מנהלית לעסק (צ\"מ)"
  },
  "רע\"מ": {
    "title": "רשות ערעור מנהלי",
    "sym": "רע\"מ",
    "algolia": "רשות ערעור מנהלי (רע\"מ)"
  },
  "נ\"ב": {
    "title": "תאונת דרכים ללא נפגעי גוף",
    "sym": "נ\"ב",
    "algolia": "תאונת דרכים ללא נפגעי גוף (נ\"ב)"
  },
  "תפ\"ב": {
    "sym": "תפ\"ב",
    "title": "תיק פלילי בניה ",
    "algolia": "תיק פלילי בניה (תפ\"ב)"
  },
  "עמ\"מ": {
    "title": "ערעור מעצר מנהלי",
    "sym": "עמ\"מ",
    "algolia": "עמ\"מ (עמ\"מ)"
  },
  "ע\"ו": {
    "title": "ערעור על ועדה",
    "sym": "ע\"ו",
    "algolia": "ערעור על ועדה (ע\"ו)"
  },
  "עק\"פ": {
    "title": "ערעור על קובלנה פלילית ע\"י הקובל",
    "sym": "עק\"פ",
    "algolia": "ערעור על קובלנה פלילית ע\"י הקובל (עק\"פ)"
  },
  "בת\"ת": {
    "title": "בקשה לתשלום תכוף",
    "sym": "בת\"ת",
    "algolia": "בקשה לתשלום תכוף (בת\"ת)"
  },
  "המ\"ד": {
    "sym": "המ\"ד",
    "title": "העברת מקום דיון/איחוד תיקים ",
    "algolia": "העברת מקום דיון/איחוד תיקים (המ\"ד)"
  },
  "עבח\"י": {
    "title": "ערעור בחירות",
    "sym": "עבח\"י",
    "algolia": "עבח\"י (עבח\"י)"
  },
  "רחד\"פ": {
    "sym": "רחד\"פ",
    "title": "רשות ערעור חדלות פירעון ",
    "algolia": "רשות ערעור חדלות פירעון (רחד\"פ)"
  },
  "עש\"ר": {
    "title": "ערעור על החלטת שר העבודה והרווחה",
    "sym": "עש\"ר",
    "algolia": "ערעור על החלטת שר העבודה והרווחה (עש\"ר)"
  },
  "עת\"צ": {
    "sym": "עת\"צ",
    "title": "ערעור תובענה ייצוגית ",
    "algolia": "ערעור תובענה ייצוגית (עת\"צ)"
  },
  "בש\"ז": {
    "sym": "בש\"ז",
    "title": "בקשה לשחזור תיק ",
    "algolia": "בקשה לשחזור תיק (בש\"ז)"
  },
  "חע\"ק": {
    "title": "חוקי עזר עירוניים - ברירת קנס",
    "sym": "חע\"ק",
    "algolia": "חוקי עזר עירוניים - ברירת קנס (חע\"ק)"
  },
  "חד\"א": {
    "sym": "חד\"א",
    "title": "חיקור דין אזרחי ",
    "algolia": "חיקור דין אזרחי (חד\"א)"
  },
  "ע\"ק": {
    "sym": "ע\"ק",
    "title": "ערר על החלטת קצין ",
    "algolia": "ערר על החלטת קצין (ע\"ק)"
  },
  "נב\"א": {
    "sym": "נב\"א",
    "title": "תיק נ\"ב אדום ",
    "algolia": "תיק נ\"ב אדום (נב\"א)"
  },
  "הפ\"מ": {
    "sym": "הפ\"מ",
    "title": "בקשה להארכת פסילה מנהלית ",
    "algolia": "בקשה להארכת פסילה מנהלית (הפ\"מ)"
  },
  "מב\"ס": {
    "sym": "מב\"ס",
    "title": "מבנים מסוכנים ",
    "algolia": "מבנים מסוכנים (מב\"ס)"
  },
  "סב\"א": {
    "title": "תביעות בין ארגוניות",
    "sym": "סב\"א",
    "algolia": "תביעות בין ארגוניות (סב\"א)"
  },
  "ת\"ח": {
    "title": "תביעת חפצא",
    "sym": "ת\"ח",
    "algolia": "תביעת חפצא (ת\"ח)"
  },
  "נמ\"ב": {
    "sym": "נמ\"ב",
    "title": "בקשה לנשיאת מאסר בישראל ",
    "algolia": "בקשה לנשיאת מאסר בישראל (נמ\"ב)"
  },
  "פק\"ח": {
    "title": "פיקוח וחוקים אחרים",
    "sym": "פק\"ח",
    "algolia": "פיקוח וחוקים אחרים (פק\"ח)"
  },
  "רע\"ר": {
    "sym": "רע\"ר",
    "title": "רשות ערעור על החלטת רשם המרכז ",
    "algolia": "רשות ערעור על החלטת רשם המרכז (רע\"ר)"
  },
  "תמ\"ר": {
    "title": "תביעה ממוכנת בסדר דין רגיל",
    "sym": "תמ\"ר",
    "algolia": "תביעה ממוכנת בסדר דין רגיל (תמ\"ר)"
  },
  "גז\"ז": {
    "title": "תביעות לפי חוק הגזזת",
    "sym": "גז\"ז",
    "algolia": "תביעות לפי חוק הגזזת (גז\"ז)"
  },
  "תל\"א": {
    "title": "תיק לאיתור",
    "sym": "תל\"א",
    "algolia": "תיק לאיתור (תל\"א)"
  },
  "תל\"פ": {
    "title": "תיק לאיתור פלילי",
    "sym": "תל\"פ",
    "algolia": "תיק לאיתור פלילי (תל\"פ)"
  },
  "תפ\"ק": {
    "sym": "תפ\"ק",
    "title": "תיק פלילי קהילתי  ",
    "algolia": "תיק פלילי קהילתי  (תפ\"ק)"
  },
  "אמ\"צ": {"title": "תיק אימוץ", "sym": "אמ\"צ", "algolia": "אמ\"צ (אמ\"צ)"},
  "בד\"מ": {"title": "בית דין משמעתי", "sym": "בד\"מ", "algolia": "בד\"מ (בד\"מ)"},
  "בצ\"א": {"title": "בקשה לצו אינטרנט", "sym": "בצ\"א", "algolia": "בקשה לצו אינטרנט (בצ\"א)"},
  "בשג\"ץ": {"title": "בקשות שונות בבג\"ץ", "sym": "בשג\"ץ", "algolia": "בשג\"ץ (בשג\"ץ)"},
  "בשה\"ת": {"title": "בקשת שחרור הארכת תנאים", "sym": "בשה\"ת", "algolia": "בקשת שחרור/הארכת תנאים (בשה\"ת)"},
  "ד\"ח": {"title": "דמי חבר", "sym": "ד\"ח", "algolia": "דמי חבר (ד\"ח)"},
  "המ\"מ": {"title": "השגה על מזכיר משפטי", "sym": "המ\"מ", "algolia": "השגה על החלטת מזכיר משפטי (המ\"מ)"},
  "הש\"א": {"title": "השגה אזרחית", "sym": "הש\"א", "algolia": "הש\"א (הש\"א)"},
  "הש\"מ": {"title": "השגה מנהלית", "sym": "הש\"מ", "algolia": "הש\"מ (הש\"מ)"},
  "הש\"פ": {"title": "השגה פלילית", "sym": "הש\"פ", "algolia": "הש\"פ (הש\"פ)"},
  "מס\"ט": {"title": "מינוי סניגור טרם כתב אישום", "sym": "מס\"ט", "algolia": "מינוי סניגור טרם כתב אישום (מס\"ט)"},
  "עבא\"פ": {"title": "ערעור על בקשה לאיסור פרסום שם חשוד", "sym": "עבא\"פ", "algolia": "ערעור על בקשה לאיסור פרסום שם חשוד (עבא\"פ)"},
  "עה\"ס": {"title": "ערעור על הליך הסגרה", "sym": "עה\"ס", "algolia": "עה\"ס (עה\"ס)"},
  "עחד\"ת": {"title": "ערעור חדלות פירעון תאגיד", "sym": "עחד\"ת", "algolia": "ערעור חדלות פרעון תאגיד (עחד\"ת)"},
  "עע\"א": {"title": "ערעור עתירות אסירים", "sym": "עע\"א", "algolia": "עע\"א (עע\"א)"},
  "עצ\"מ": {"title": "ערר על צו מניעה סגירה מנהלית לעסק", "sym": "עצ\"מ", "algolia": "ערר על צו מניעה - סגירה מנהלית לעסק (עצ\"מ)"},
  "ערג\"ץ": {"title": "ערעור על רשם בבג\"ץ", "sym": "ערג\"ץ", "algolia": "ערג\"ץ (ערג\"ץ)"},
  "עש\"ם": {"title": "ערעור משמעתי עובדי מדינה", "sym": "עש\"ם", "algolia": "עש\"ם (עש\"ם)"},
  "פמ\"ר": {"title": "פסילת משרד רישוי", "sym": "פמ\"ר", "algolia": "פסילת משרד רישוי (פמ\"ר)"},
  "פש\"ז": {"title": "בקשה לשחזור תיק פלילי", "sym": "פש\"ז", "algolia": "בקשה לשחזור תיק פלילי (פש\"ז)"},
  "תד\"א": {"title": "תיק ת\"ד אדום", "sym": "תד\"א", "algolia": "תיק ת\"ד אדום (תד\"א)"},
  "עא\"ח" :{"title":"ערעור אחר","sym":"עא\"ח","algolia":"עא\"ח (עא\"ח)"},
  "עד\"מ": {"title":"ערעור דיון מהיר","sym":"עד\"מ","algolia":"עד\"מ (עד\"מ)"},
  "ע\"ב": {"title":"תיק בית דין לעבודה","sym":"ע\"ב","algolia":"ע\"ב (ע\"ב)"},
  "עס\"א":{"title":"ערעור סכסוך ארגוני","sym":"עס\"א","algolia":"עס\"א (עס\"א)"},
};

let groups = [
  {
    name: "בג\"ץ",
    list: ["בג\"ץ", "דנג\"ץ", "בשג\"ץ", "ערג\"ץ"]
  },
  {
    name: "מנהלי",
    list: 'בר"מ בש"מ דנ"מ עת"מ רע"מ עת"א עמ"נ ת"מ עע"מ עמ"מ תק"ח עח"ק רע"ס צ"מ ע"ק עמ"א פק"ח חע"ק חע"מ בע"א תפ"ב עתפ"ב תו"ח תו"ב בב"נ בצה"מ בצה"מ מב"ס צ"ה ו"ע חע"מ ע"מ ו"ע א"ב ע"ב הש"מ מב"ס עע"א עפמ"ק ע"ק עצ"מ'.split(/ /)
  },
  {
    name: "פלילי",
    list: 'ה"ת נמ"ב ת"פ רע"פ עק"פ ע"פ עפ"א עפ"ג עפמ"ק תפ"ח בש"פ דנ"פ ק"פ מ"י מ"ת עמ"ת מ"ח בע"ח ע"ח צ"א תה"ג עמ"י תל"פ בש"ע תפ"ק בצ"א הש"פ מס"ט נמ"ב עבא"פ עה"ס פש"ז'.split(/ /)
  },
  {
    name: "מעצרים",
    list: 'מ"י מ"ת בש"ע עמ"י בשה"ת'.split(/ /)
  },
  {
    name: "תעבורה",
    list: 'בפ"ת עמ"ת ע"ח בע"ח בש"ע מ"ח ח"נ תת"ח תת"ע ת"ד נ"ב המ"ש פ"ל בפ"מ בא"ש הפ"מ מ"ת תתע"א נב"א פל"א עפ"ת גמ"ר הפ"מ נב"א פמ"ר תד"א תתע"א'.split(/ /)
  },
  {
    name: "דיני עבודה",
    list: 'ע"ע ק"ג ד"מ ד"ט ס"ע סע"ש ס"ק סק"כ סב"א תע"א עס"ק עש"ר עק"ג על"ח בע"ק בר"ע המ"ד ת"ב צ"ו פ"ה צ"א עת"צ עפ"ס הע"ז המ"ש עפ"א ע"פ ע"ר ת"צ גז"ז חב"ר ד"ח עש"ם'.split(/ /)
  },
  {
    name: "ביטוח לאומי",
    list: ["ב\"ל", "עב\"ל"]
  },
  {
    name: "חדלות פירעון",
    list: 'חדל"פ חדל"ת עחדל"פ רחד"פ פש"ר פר"ק עחד"ת רחד"פ'.split(/ /)
  },
  {
    name: "הוצאה לפועל",
    list: ["רצ\"פ", "רע\"צ", "רע\"ר", "רער\"צ", "ת\"ת", "ת\"ט", "בע\"ק", "עש\"א"]
  },
  {
    name: "משפחה",
    list: 'תנ"ז תמה"ח תמ"ש תלה"מ תה"ס ת"ע ת"ג ש"ש רמ"ש צב"נ עמ"ש י"ס ה"נ א"פ אמ"צ בע"מ'.split(/ /)
  },
  {
    name: "אזרחי",
    list: 'בר"ע בר"ש בש"א בש"ז בת"ת דנ"א ה"ט ה"פ המ"ד הפ"ב חד"א ע"א ע"ו ע"נ ע"ר עב"י עש"א עש"א עת"צ פ"ה צ"ו רע"א רע"ב רת"ק ש ת"א ת"ב ת"ח ת"צ ת"ק תא"ח תא"מ תא"ק תאד"מ תל"א תמ"ר תנ"ג תפ"מ בד"מ המ"מ הש"א חד"א'.split(/ /)
  },
]

class CaseTypes {
  extract(s) {
    let split = s.split(/[()]/);

    return split[split.length - 2];
  }

  find(s) {
    return this.all[this.extract(s) || s];
  }

  sorted() {
    return _.sortBy(_.values(this.all), 'sym');
  }

  prod() {
    return _.values(this.all).map(ct => ct.algolia).sort();
  }

  groupList(g) {
    return _.find(groups, {name: g}).list;
  }

  groups() {
    // if (gon.user.plan === 'admin')
    return groups;
    // else
    //   return [groups[1], groups[2], groups[3]];
  }

  constructor() {
    this.all = typeMap;
    return;

    let h = {};
    this.all = {};

    for (let a of all) {
      h[a[1]] = {title: a[0], sym: a[1]};
    }

    for (let p of prod) {
      let sym = this.extract(p);

      let obj = this.all[sym] = h[sym];
      if (!obj) {
        let title = p.replace(/\(.+?\)$/, '');
        obj = {sym, title}
        this.all[sym] = obj;
      }

      obj.algolia = p;
    }
  }
}

export default new CaseTypes();