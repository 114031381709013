import {observable} from "mobx";
import utils from "./utils";
import Doc from "./Doc";
import axios from "axios";

export default observable({
  id: null,
  mode: null,
  article: null,
  title: null,
  caseNum: null,
  locate: null,
  dl_url: null,
  summary: null,
  issues: null,
  bestParts: [],
  allParts: [],
  articleLines: [],
  ref_case_nums: [],
  openSummary: false,
  term: null,
  tikParts: null,
  chat: null,

  load(article, line) {
    this.locate = line;
    this.article = article;
    this.chat = null;

    if (article) {
      this.id = article.id;
      this.html = article.html;
      this.kind = article.kind;
      this.title = article.title;
      this.caseNum = article.case_num;
      this.dl_url = article.dl_url;
      this.articleLines = article.lines;
      this.strParts = article.str_parts;
      this.bestParts = article.best;
      this.summary = article.summary;
      this.issues = article.issues;
      this.ref_case_nums = article.ref_case_nums;
      this.tikParts = article.tik_parts;
    }
  },

  async markedLines(term, type) {
    let id = this.id;
    // skira
    if (this.kind === 2)
      id = "s" + id;

    let url = `/articles/${id}/lines?&term=` + encodeURI(term) + "&type=" + type;

    let data = (await axios.get(url)).data;

    return data.marked_lines;
  },

  asDoc() {
    // let prefix = `${a.short_case_type} (${a.court_name}) ${a.case_num} <b>${a.case_title}</b> (${a.date})`;
    let a = this.article;
    return new Doc({title: a.case_title, date: a.date, court: a.court_name, type: a.short_case_type, num: a.case_num});
  },

  cleanParts() {
    return this.strParts ? this.strParts.map(s => utils.cleanFootnote(s)) : [];
  }
});