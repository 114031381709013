import React from 'react';

const Switcher = ({v, vals, switchTwo, children}) => {
  let chosen=null;

  if (switchTwo)
    chosen = children[v ? 0 : 1];
  else
  if (v)
    chosen = vals ? children[vals.indexOf(v)] : children;
  else
  if (vals)
    chosen = children[vals.indexOf(true)];

  return chosen
}

export const Showable = ({v, alt, children}) => !!v ? children : (alt || null);
export const Switch2 = ({v, children}) => <Switcher switchTwo v={v} children={children} />
export const SwitchEmpty = ({v, children}) => <Switcher switchTwo v={!!v?.length} children={children} />

export default Switcher

