import React, {useEffect, useRef} from 'react';
import Mark from 'mark.js';

export const ColorArea = ({regexps, posfix, children}) => {
  const el = useRef(null);

  useEffect(() => {
    if (!el || !el.current)
      return;

    let dom = el.current;
    setTimeout(() => {
      let marker = new Mark(dom);

      for (let i = 0; i < regexps.length; i++)
        marker.markRegExp(regexps[i], {className: "mark-" + (posfix || i % 8)});

    }, 150)
  });

  return <div className="mark-focus" ref={el}>
    {children}
  </div>
};
