import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {Affix, Button, Drawer, Menu} from "antd";
import {RightOutlined} from "@ant-design/icons";
import {TabBar} from "antd-mobile";
import Device from "./Device";
import {Header} from "antd/lib/layout/layout";

export default observer(({rightDrawer, tabs, header, inner, close, mode, handleModeChange}) => {
  if (Device.xs) {
    let mobileHeader = <Header style={{backgroundColor: 'white'}}>
      <div className="horizontal-flex-between">
        <div onClick={close} style={{marginLeft: "0.5rem"}}><RightOutlined /></div>
        {header}
      </div>
    </Header>

    let mobileFooter = tabs && <div style={{position: 'fixed', bottom: 0, width: "100%", backgroundColor: 'white', borderTop: "1px solid gray", paddingTop: "0.5rem"}}>
      <TabBar activeKey={mode} onChange={handleModeChange}>
        {tabs.map(m => <TabBar.Item style={{color: m[3] ? "#d1d5db" : null}} key={m[0]} icon={m[2]} title={m[1]} />)}
      </TabBar>
    </div>

    return <Drawer
      className="mobile-drawer"
      width="100%"
      closable={false}
      headerStyle={{padding: 0}}
      footerStyle={{padding: 0}}
      title={mobileHeader}
      placement="left"
      onClose={close}
      visible={true}
      footer={mobileFooter}
    >
      <div style={{padding: '1rem 1rem 4rem 1rem'}}>{inner}</div>
    </Drawer>
  }

  let drawerSize = Device.xl ? "60%" : "80%";
  if (rightDrawer)
    drawerSize = "55%";

  let desktopHeader = <div>
    {header}
    <Menu onClick={handleModeChange} selectedKeys={[mode]} mode="horizontal"
          items={_.filter(tabs, m => !m[3]).map(m => ({key: m[0], icon: m[2], label: m[1] }))} />
  </div>

  return <Drawer
    className="article-drawer"
    width={drawerSize}
    closable={false}
    title={desktopHeader}
    placement="left"
    onClose={close}
    visible={true}
    mask={!rightDrawer}
  >
    {inner}
  </Drawer>
})