import React, {useEffect, useState} from "react";
import {Button, Popover, Tooltip} from "antd";
import utils from "./utils";

export default ({children, placement, doc, asClick, disabled, forceLink}) => {
  let click = e => {
    e.stopPropagation();
    let text = doc.mm();
    utils.copy(text);
  }

  let content = <Button type="link" onClick={click}>העתק מראה מקום</Button>
  let mobile = window.innerWidth < 700;

  if (disabled)
    return children;

  if (asClick)
    return <span className="mm-pop" onClick={click}>
      <Tooltip placement={placement} title="לחצו להעתקת מראה מקום">
        {children}
      </Tooltip>
  </span>;

  if (mobile || forceLink)
    return <Button type="link" onClick={click}>
      {children}
  </Button>;

  return <Popover content={content} placement={placement} color="red" overlayClassName="mm-pop" triggger="click">
    {children}
  </Popover>
}