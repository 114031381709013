import _ from 'lodash';

let courts = {
  "1": "עליון",
  "2": "הארצי לעבודה",
  "3": "אזורי לעבודה ירושלים",
  "4": "המחוזי תל אביב - יפו",
  "5": "המחוזי ירושלים",
  "6": "שלום רמלה",
  "8": "המחוזי מרכז",
  "9": "שלום כפר סבא",
  "10": "אזורי לעבודה תל אביב - יפו",
  "11": "שלום תל אביב - יפו",
  "12": "שלום ירושלים",
  "13": "שלום חדרה",
  "15": "אזורי לעבודה באר שבע",
  "16": "אזורי לעבודה חיפה",
  "19": "אזורי לעבודה נצרת",
  "21": "אזורי לעבודה ב\"ש בשבתו באילת",
  "22": "המחוזי נצרת",
  "23": "שלום פתח תקווה",
  "24": "שלום עכו",
  "25": "שלום קריות",
  "26": "שלום חיפה",
  "27": "המחוזי חיפה",
  "28": "המחוזי באר שבע",
  "29": "שלום רחובות",
  "31": "שלום טבריה",
  "32": "שלום אשקלון",
  "33": "שלום דימונה",
  "34": "שלום נצרת",
  "35": "שלום באר שבע",
  "36": "תעבורה מחוז מרכז",
  "37": "שלום נתניה",
  "38": "שלום בת ים",
  "39": "שלום בית שאן",
  "40": "עניינים מקומיים באר שבע",
  "41": "שלום ראשון לציון",
  "42": "עניינים מקומיים ירושלים",
  "43": "שלום קריית גת",
  "44": "עניינים מקומיים אריאל",
  "45": "שלום הרצליה",
  "46": "שלום קריית שמונה",
  "47": "שלום עפולה",
  "48": "שלום אשדוד",
  "49": "עניינים מקומיים הרצליה",
  "50": "שלום קצרין",
  "51": "עניינים מקומיים חיפה",
  "52": "עניינים מקומיים פתח תקווה",
  "55": "שלום אילת",
  "56": "שלום צפת",
  "57": "שלום מסעדה",
  "58": "עניינים מקומיים חדרה",
  "59": "שלום בית שמש",
  "60": "עניינים מקומיים כפר סבא",
  "61": "עניינים מקומיים קריות",
  "62": "עניינים מקומיים תל אביב - יפו",
  "63": "עניינים מקומיים נתניה",
  "64": "עניינים מקומיים רמת גן",
  "65": "עניינים מקומיים ראשון לציון",
  "66": "עניינים מקומיים בני ברק",
  "67": "עניינים מקומיים רחובות",
  "68": "עניינים מקומיים כרמיאל",
  "69": "רבני תל אביב",
  "70": "רבני פתח תקווה",
  "71": "רבני חיפה",
  "72": "רבני אשדוד",
  "73": "רבני רחובות",
  "74": "רבני הגדול",
  "75": "רבני אשקלון",
  "76": "רבני ירושלים",
  "77": "רבני באר שבע",
  "78": "רבני צפת",
  "79": "רבני נתניה",
  "80": "רבני טבריה",
  "81": "רבני אריאל",
  "83": "בתי המשפט לענייני משפחה",
  "97": "עניינים מקומיים מעלה אדומים"
}

export const courtAbbr = (courtId) => {
  if (courtId) {
    if (courtId === 2)
      return "ארצי לעבודה";
    else {
      if (courtId === 1)
        return "עליון";
      else {
        if ([3, 10, 15, 16, 17, 18, 19, 20, 21, 30].indexOf(courtId) >= 0)
          return "איזורי לעבודה";
        else {
          if ([4, 5, 7, 8, 22, 27, 28].indexOf(courtId) >= 0)
            return "מחוזי";
          else {
            if ([69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81].indexOf(courtId) >= 0)
              return "רבני";
            else {
              if (courtId === 83 || courtId === 89)
                return "משפחה"
              else
                return "שלום";
            }
          }
        }
      }
    }
  }

  return null;
}
export const courtsSet = new Set(_.values(courts));
export const allCourtsButHigh = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 89];

export default courts;