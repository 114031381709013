import React from 'react';

export default {
  search(q) {
    window.open("/search?q=" + q, '_blank');
  },

  link(q, title) {
    return <a href={"/search?q="+q} target="_blank">{title || q}</a>;
  }
}