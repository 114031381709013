import {message, Modal} from "antd";
import axios from "axios";

export const pp = obj => {
  if (obj)
    console.log(JSON.parse(JSON.stringify(obj)))
  else
    console.log(obj);
}

export const callWithCatch = async ({url, params, method, onErr, onSuccess, setXhr, setLoading, keepLoading}) => {
  try {
    setXhr = setXhr || setLoading;
    setXhr && setXhr(true);
    if (method === "get")
      params = { params };

    let data;
    if (params.file) {
      const formData = new FormData();
      for (const name in params) {
        formData.append(name, params[name]);
      }
      data = (await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }})).data;
    }
    else
      data = (await axios[method](url, params)).data;

    if (data.err) {
      Modal.error({title: data.err_title, content: data.err, zIndex: 2000});
      onErr && onErr(data.err);
    }
    else {
      onSuccess && onSuccess(data);
    }
    setXhr && !keepLoading && setXhr(false);
  }
  catch(e) {
    setXhr && setXhr(false);
    message.error("Unknown error");
    onErr && onErr(e);
    throw e;
  }
}

export default {
  cleanFootnote(s) {
    return s.replace(/\[footnoteRef:\d+\]/, "");
  },

  copy(t, v, html) {
    if (html) {
      var type = "text/html";
      var blob = new Blob([t.replace(/\n/g, "<br/>")], { type });
      var data = [new ClipboardItem({ [type]: blob })];
      navigator.clipboard.write(data)
    }
    else {
      navigator.clipboard.writeText(t);
    }
    message.success(v || ('הועתק: ' + t));
  },

 clearTextSelection() {
    if (window.getSelection) {window.getSelection().removeAllRanges();}
    else if (document.selection) {document.selection.empty();}
  }
}