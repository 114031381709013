import store from 'store';
import Device from "./Device.js";
import posthog from "posthog-js";

class User {
  constructor() {
    if (gon.user)
      this.user = gon.user;
  }

  isAdmin() {
    return this.user?.plan === "admin";
  }

  tester() {
    return this.user.name === "בדיקה";
  }

  beta() {
    return this.isAdmin() || gon.beta;
  }

  isNew() {
    return this.tester() || this.user.days < 4;
  }

  track(event, eventProperties) {
    try {
      if (Device.local)
        console.log("track: " + event, eventProperties);
      else
        posthog.capture(event, eventProperties);
    } catch (e) {
      console.log(e)
    }
  }

  isRabniFam() {
    return gon.user && gon.user.plan === "rab_fam"
  }

  ronen() {
    return this.user.name === "רונן";
  }

  isPaying() {
    let {status} = this.user;

    return status === "monthly" || status === "yearly" || status.match(/team/)
  }

  isClosed() {
    return this.user.closed
  }

  setCid(cid) {
    if (cid)
      store.set('cid', cid);
  }

  cid() {
    let cid = store.get('cid');

    if (cid)
      return cid;

    if (!this.user)
      return;

    if (this.user.cid)
      store.set('cid', this.user.cid);

    return this.user.cid;
  }
}

const user = new User();

export default user;