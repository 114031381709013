export default {
  get() {
    return window.getSelection().toString().trim();
  },

  clear() {
    if (window.getSelection)
      window.getSelection().removeAllRanges();
    else if (document.selection)
      document.selection.empty();
  }
}