import {Button, Dropdown, Menu, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import React from "react";

export default ({onClick, options, v, def, noDefSelection, ...ddOpts}) => {
  if (options.length) {
    if (!options[0].key) {
      if (_.isArray(options[0]))
        options = options.map(o => ({ key: o[0], label: o[1] }));
      else
        options = options.map(o => ({ key: o, label: o }));
    }
  }
  else
    options = [];

  const menu = (
    <Menu onClick={onClick} items={options}/>
  );

  let option = _.find(options, { key: v });

  if (!noDefSelection)
    options.unshift({key: false, label: def})

  return <Space>
    <Dropdown overlay={menu} trigger={['click']} {...ddOpts}>
      <Button type={option && "primary"}>
        {option ? option.label : def } <DownOutlined />
      </Button>
    </Dropdown>
  </Space>
};
