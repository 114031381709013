import actioncable from 'actioncable';
let prefix = import.meta.env.DEV ? "ws://localhost:3007" : "wss://www.dinrega.com";


export default (params, onData) => {
  const cable = actioncable.createConsumer(`${prefix}/cable`)
  return cable.subscriptions.create(params, {
    received(data) {
      onData(data);
    }
  });
}