import {observer} from "mobx-react";
import React, {useEffect} from "react";
import utils from "./utils";
import Mark from 'mark.js';

export default observer(({prefix, markedLine}) => {
  let id = prefix + markedLine.id;

  useEffect(() => {
    let el = document.getElementById(id);
    let marker = new Mark(el);
    for (let {match, section} of markedLine.matches) {
      marker.markRegExp(new RegExp(match), {className: "mark-" + section});
    }
  });

  return <div id={id} className="marked-line" style={{padding: "0.5rem", direction: 'rtl', textAlign: 'right'}}>
    {utils.cleanFootnote(markedLine.line)}
  </div>
});

