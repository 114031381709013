import React from "react";
import {Spin} from "antd";

export default ({loading, children}) => {
  if (loading)
    return <Spin />;

  return children;
};

