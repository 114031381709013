export default class Doc {
  constructor(data) {
    this.data = data;
  }

  addCourtView(a) {
    let cn = this.data.court;
    let v = cn;

    if (!cn || cn.match(/עליון/))
      return;

    if (cn.match(/ארצי/))
      v = "ארצי" ;

    if (this.data.type.match(/ת"ק/))
      v = v.replace(/ה?שלום/, "תביעות קטנות");

    v = v.replace(/המחוזי/, "מחוזי");
    v = v.replace(/לעבודה/, "");

    a.push(`(${v})`);
  }

  caseNum() {
    let n = this.data.num;

    let m = n.match(/[\d-ֿ\/]+/);

    return m[0];
  }

  caseType() {
    let t = this.data.type;

    let m = t.match(/.+?\((.+?)\)$/);

    if (!m)
      return t;

    return m[1];
  }

  mm() {
    let d = this.data;

    let a = [this.caseType()];
    this.addCourtView(a);

    a.push(this.caseNum());

    if (!this.data.court || !this.data.court.match(/רבני/))
      a.push(d.title);

    a.push(`(${d.date})`);

    return a.join(" ");
  }
}
